import React from "react";
import { useState, createContext, useEffect } from "react";


export const MedicalContext = createContext();

const MedicalContextProvider = (props) => {

  const [PerRecordsCodes, SetPerRecordsCodes] = useState(() => {
    const localData = localStorage.getItem("PerRecordsCodes");
    return localData ? JSON.parse(localData) : 
    {
      "Sobrepeso": 'AP009',
      "Obesidad": 'AP006',
      "Hipertensión": 'AP003',
      "Resistencia a la insulina": 'AP011',
      "Diabetes": 'AP002',
      "Rinitis alérgica": 'AP005',
      "Asma": 'AP004',
      "Hipotiroidismo": 'AP001',
      "Tabaquismo": 'AP010', 
      "EPOC": 'AP013',
      "Colesterol Alto": 'AP012',
      "Hipoacusia": 'AP014',
      "Osteoporosis": 'AP015',
      "Enfermedad renal crónica": 'AP016',
      "Glaucoma": 'AP017',
      "Síndrome ovario poliquístico": 'AP018',
      "Anemia": 'AP019',
      "Menopausia": 'AP020'
    };
  });

  const [FamRecordsCodes, SetFamRecordsCodes] = useState(() => {
    const localData = localStorage.getItem("FamRecordsCodes");
    return localData ? JSON.parse(localData) : 
    {
      "Resistencia a la insulina": 'AF008',
      "Diabetes": 'AF007',
      "Hipertensión": 'AF021',
      "Rinitis alérgica": 'AF022',
      "Hipotiroidismo": 'AF023',
      "Infarto": 'AF024',
      "ACV": 'AF025',
      "Demencia - enfermedad de alzheimer": 'AF026',
      "Glaucoma": 'AF027',
      "Hipoacusia": 'AF028',
      "Cáncer Colorrectal": 'AF029',
      "Cáncer de Estómago": 'AF030',
      "Cáncer de Pulmón": 'AF031',
      "Cáncer de vesícula": 'AF032',
      "Cáncer de próstata": 'AF033',
      "Cáncer de mama": 'AF034'
    };
  });

  const [RelatedExams, SetRelatedExams] = useState(() => {
    const localData = localStorage.getItem("RelatedExams");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem(
      "RelatedExams",
      JSON.stringify(RelatedExams)
    );
  }, [RelatedExams]);

  const [PreviewRelatedExams, SetPreviewRelatedExams] = useState(() => {
    const localData = localStorage.getItem("PreviewRelatedExams");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem(
      "PreviewRelatedExams",
      JSON.stringify(PreviewRelatedExams)
    );
  }, [PreviewRelatedExams]);



  return (
    <MedicalContext.Provider
      value={{
        PerRecordsCodes,
        FamRecordsCodes,
        RelatedExams, SetRelatedExams, PreviewRelatedExams, SetPreviewRelatedExams
      }}>
      {props.children}
    </MedicalContext.Provider>
  );
};
export default MedicalContextProvider;
