import React, { useContext, useState, useEffect } from 'react';
import "../../Styles/ExamChoiceByGender.css";
import { useNavigate } from 'react-router-dom';
import { Packs } from '../Context/PacksContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import TinyPageIcon from "../../Photos/TinyPageIcon.png"
import TinyExamIcon from "../../Photos/TinyExamIcon.png"
import packslist from "../../../JSONS/updatedPacks.json"
import examslist from "../../../JSONS/updatedExams.json"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MakeItEasy from "../Landing/MakeItEasy"


function ExamChoiceByGenderRoute() {

  const Navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [VisualizePack,SetVisualizePack] = useState(searchParams.get("pack"))

  const MySwal = withReactContent(Swal)

  useEffect(() => {
    if(!searchParams.get("pack")) {
      Navigate("/packs-de-examenes")
    }
  })

  window.onscroll = function() {
    if(document.URL.includes("detalles-de-pack")) {
      let tmp = document.getElementById("ToTheTop")
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        tmp.className = tmp.className.replace(" ScrollBackToTopHidden", "");
      } else {
        if(!tmp.className.includes("ScrollBackToTopHidden")) tmp.className += " ScrollBackToTopHidden"
      }
    }
  }
  
  const {
    BasketPacks, SetBasketPacks, ExamsPerProduct, SetExamsPerProduct
  } = useContext(Packs);

  const others = ["Imagenología", "Procedimientos"]

  const [Gender, setGender] = useState(VisualizePack === null || VisualizePack.length === 2 ? null : (packslist[VisualizePack].gender === "Mixed" | packslist[VisualizePack].gender.length === 0) & !packslist[VisualizePack].depends ? 'None' : packslist[VisualizePack].gender === "M" ? 'M' : 'F');
  const [ChosenExams, setChosenExams] = useState([]);
  const [OneTime, setOneTime] = useState(true);
  const [ProPrice, setProPrice] = useState(VisualizePack === null || VisualizePack.length === 2 ? null : packslist[VisualizePack].price);
  const [Page,setPage] = useState(1)
  const [ExamsShown,setExamsShown] = useState(window.innerWidth <= 420 ? 1 : window.innerWidth <= 720 ? 2 : 4)
  const [PackPage,setPackPage] = useState(1)
  const [PacksShown,setPacksShown] = useState(window.innerWidth <= 620 ? 1 : window.innerWidth <= 920 ? 2 : 3)

  function ChangePack(newPack) {
    setChosenExams([])
    setProPrice(packslist[newPack].price)
    setGender((packslist[newPack].gender === "Mixed" | packslist[VisualizePack].gender.length === 0) & !packslist[newPack].depends ? 'None' : packslist[newPack].gender === "M" ? 'M' : 'F')
    SetVisualizePack(newPack)
    setOneTime(true)
  };

  useEffect(() => {
    if(OneTime && document.getElementById("ManageAll")) {
      PreSelect()
      setOneTime(false)
      Navigate("/detalles-de-pack?pack=".concat(VisualizePack))
    }
  })

  function PreSelect(gender = Gender) {
    if(gender === "None") {
      let Selected = packslist[VisualizePack].exams.filter((a,ind) => packslist[VisualizePack].selected[ind])
      let loadedExams = packslist[VisualizePack].exams.filter(a => examslist[a].emission !== "")
      if(VisualizePack === "PCM00YMC008") {
        for(let i=0;i<loadedExams.length;i++) {
          if(Selected.includes(loadedExams[i]) && loadedExams[i] !== "YMC008" && loadedExams[i] !== "YMC009" && loadedExams[i] !== "YMC043") {
            let tmp = document.getElementById(loadedExams[i])
            if(tmp) tmp.checked = true
          }
        }
        let tmp = document.getElementById("PCM00YMC008")
        if(tmp) tmp.checked = true
      } else {
        for(let i=0;i<loadedExams.length;i++) {
          if(Selected.includes(loadedExams[i])) {
            let tmp = document.getElementById(loadedExams[i])
            if(tmp) tmp.checked = true
          }
        }
      }
      setChosenExams(Selected.filter(a => loadedExams.includes(a)))
      //console.log(loadedExams)
    } else {
      let Selected = packslist[VisualizePack][gender].exams.filter((a,ind) => packslist[VisualizePack][gender].selected[ind])
      let loadedExams = packslist[VisualizePack][gender].exams.filter(a => examslist[a].emission !== "")
      for(let i=0;i<loadedExams.length;i++) {
        if(Selected.includes(loadedExams[i])) {
          let tmp = document.getElementById(loadedExams[i])
          if(tmp) tmp.checked = true
        }
      }
      setChosenExams(Selected.filter(a => loadedExams.includes(a)))
      //console.log(Selected)
    }
    //let checked={Selected.includes(key) ? true : false}
  }

  function toggleBack(current) {
    let tmp = document.getElementById("FemaleTab")
    let tmp2 = document.getElementById("MaleTab")
    if(current) {
      setGender('M')
      tmp.className = tmp.className.replace(" ExamChoiceByGenderActiveTag", "");
      tmp2.className += " ExamChoiceByGenderActiveTag";
      document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
      //setChosenExams([]);
    } else {
      setGender('F')
      tmp2.className = tmp2.className.replace(" ExamChoiceByGenderActiveTag", "");
      tmp.className += " ExamChoiceByGenderActiveTag";
      document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
      //setChosenExams([]);
    }
    setPage(1)
    let tmp3 = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot")
    for(let i=0;i<tmp3.length;i++) {
      if(tmp3[i].className.includes("ActiveDot")) {
        tmp3[i].className = tmp3[i].className.replace(" ActiveDot", "");
      }
    }
    let tmp4 = document.getElementById('pageDot'.concat(1))
    tmp4.className += " ActiveDot"
    setOneTime(true)
    setProPrice(packslist[VisualizePack].price)
  }

  function handleChosen(id) {
    let tmp = document.getElementById(id)
    let listtmp = ChosenExams
    let fullprice = ProPrice
    if(id == 'ChooseAll') {
      if (tmp.checked) {
        fullprice = packslist[VisualizePack].price
        if(Gender === "None") {
          for(let i=0; i<packslist[VisualizePack].exams.length; i++) {
            let tmp2 = document.getElementById(packslist[VisualizePack].exams[i])
            if(tmp2) {
              tmp2.checked = true
              listtmp.push(packslist[VisualizePack].exams[i])
            }
          }
          for(let i=0; i<packslist[VisualizePack].examsPro.length; i++) {
            let tmp2 = document.getElementById(packslist[VisualizePack].examsPro[i])
            if(tmp2) {
              tmp2.checked = true
              fullprice += packslist[VisualizePack].pricesPro[i]
              listtmp.push(packslist[VisualizePack].examsPro[i])
            } 
          }
          if(VisualizePack === "PDM00YMC005") {
            let tmp2 = document.getElementById("PDM00YMC005")
            tmp2.checked = true
            let group = ["YMC036","YMC037"]
            listtmp.push("YMC036","YMC037")
            let indexPro = packslist[VisualizePack].examsPro.indexOf(group[0]);
            fullprice += packslist[VisualizePack].pricesPro[indexPro]
          }
          if(VisualizePack === "PCM00YMC008") {
            let tmp2 = document.getElementById("PCM00YMC008")
            tmp2.checked = true
            let group = ["YMC008","YMC009","YMC043"]
            listtmp.push("YMC008","YMC009","YMC043")
          }
        } else {
          for(let i=0; i<packslist[VisualizePack][Gender].exams.length; i++) {
            let tmp2 = document.getElementById(packslist[VisualizePack][Gender].exams[i])
            if(tmp2) {
              tmp2.checked = true
              listtmp.push(packslist[VisualizePack][Gender].exams[i])
            }
          }
          for(let i=0; i<packslist[VisualizePack][Gender].examsPro.length; i++) {
            let tmp2 = document.getElementById(packslist[VisualizePack][Gender].examsPro[i])
            if(tmp2) {
              tmp2.checked = true
              fullprice += packslist[VisualizePack][Gender].pricesPro[i]
              listtmp.push(packslist[VisualizePack][Gender].examsPro[i])
            } 
          }
        }
      } else {
        fullprice = packslist[VisualizePack].price
        if(Gender === "None") {
          listtmp = []
          for(let i=0; i<packslist[VisualizePack].exams.length; i++) {
            let tmp2 = document.getElementById(packslist[VisualizePack].exams[i])
            if(tmp2) tmp2.checked = false
          }
          for(let i=0; i<packslist[VisualizePack].examsPro.length; i++) {
            let tmp2 = document.getElementById(packslist[VisualizePack].examsPro[i])
            if(tmp2) {
              tmp2.checked = false
            }
          }
          if(VisualizePack === "PDM00YMC005") {
            let tmp2 = document.getElementById("PDM00YMC005")
            tmp2.checked = false
          }
          if(VisualizePack === "PCM00YMC008") {
            let tmp2 = document.getElementById("PCM00YMC008")
            tmp2.checked = false
          }
        } else {
          listtmp = []
          for(let i=0; i<packslist[VisualizePack][Gender].exams.length; i++) {
            let tmp2 = document.getElementById(packslist[VisualizePack][Gender].exams[i])
            if(tmp2) tmp2.checked = false
          }
          for(let i=0; i<packslist[VisualizePack][Gender].examsPro.length; i++) {
            let tmp2 = document.getElementById(packslist[VisualizePack][Gender].examsPro[i])
            if(tmp2) {
              tmp2.checked = false
            } 
          }
        }
      }
    }
    else if(id === "PDM00YMC005") {
      let group = ["YMC036","YMC037"]
      if (tmp.checked) {
        for(let i=0;i<group.length;i++) {
          listtmp.push(group[i])
        }
        let indexPro = packslist[VisualizePack].examsPro.indexOf(group[0]);
        fullprice += packslist[VisualizePack].pricesPro[indexPro]
      } else {
        for(let i=0;i<group.length;i++) {
          let index = listtmp.indexOf(group[i]);
          if (index > -1) { 
            listtmp.splice(index, 1); 
          }
        }
        let indexPro = packslist[VisualizePack].examsPro.indexOf(group[0]);
        fullprice -= packslist[VisualizePack].pricesPro[indexPro]
      }
    } else if(id === "PCM00YMC008") {
      let group = ["YMC008","YMC009","YMC043"]
      if (tmp.checked) {
        for(let i=0;i<group.length;i++) {
          listtmp.push(group[i])
        }
      } else {
        for(let i=0;i<group.length;i++) {
          let index = listtmp.indexOf(group[i]);
          if (index > -1) { 
            listtmp.splice(index, 1); 
          }
        }
      }
    } else {
      if (tmp.checked) {
        listtmp.push(id)
        if(Gender === "None") { 
          if(packslist[VisualizePack].examsPro.includes(id)) {
            let indexPro = packslist[VisualizePack].examsPro.indexOf(id);
            fullprice += packslist[VisualizePack].pricesPro[indexPro]
          }
        }
        if(Gender !== "None"){ 
          if(packslist[VisualizePack][Gender].examsPro.includes(id)) {
            let indexPro = packslist[VisualizePack][Gender].examsPro.indexOf(id);
            fullprice += packslist[VisualizePack][Gender].pricesPro[indexPro]
          }
        }
      } else {
        let index = listtmp.indexOf(id);
        if (index > -1) { 
          listtmp.splice(index, 1); 
        }
        if(Gender === "None") { 
          if(packslist[VisualizePack].examsPro.includes(id)) {
            let indexPro = packslist[VisualizePack].examsPro.indexOf(id);
            fullprice -= packslist[VisualizePack].pricesPro[indexPro]
          }
        }
        if(Gender !== "None"){ 
          if(packslist[VisualizePack][Gender].examsPro.includes(id)) {
            let indexPro = packslist[VisualizePack][Gender].examsPro.indexOf(id);
            fullprice -= packslist[VisualizePack][Gender].pricesPro[indexPro]
          }
        }
      }
    }

    if(fullprice !== ProPrice) {
      changePriceSize()
    }

    setProPrice(fullprice)
    //console.log([...new Set(listtmp)])
    setChosenExams([...new Set(listtmp)])
  }

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  async function changePriceSize() {
    let tmpPrice = document.getElementById("PriceDiv")
    tmpPrice.className += " PriceChanged"
    await delay(1000);
    tmpPrice.className = tmpPrice.className.replace(" PriceChanged","")
  }

  function ModifyPackCheckout(pack) {
    let listOfPacks = BasketPacks
    let examsOfPacks = ExamsPerProduct
    if(BasketPacks.includes(pack)) {
        let index = listOfPacks.indexOf(pack);
        if (index > -1) { 
            listOfPacks.splice(index, 1); 
        }
        delete examsOfPacks[pack]
    } else {
        listOfPacks.push(pack)
        let dict = {
          "exams": ChosenExams,
          "price": ProPrice
        }
        examsOfPacks[pack] = {...dict}
    } 
    SetBasketPacks([...listOfPacks])
    SetExamsPerProduct({...examsOfPacks})
  }

  function toggleAdditional(id) {
    let tmp = document.getElementById(id)
    if(tmp.className.includes("InformationCollapsed")) {
      tmp.className = tmp.className.replace(" InformationCollapsed", "");
    } else {
      tmp.className += " InformationCollapsed"
    }
  }

  function toggleDescription(id,type) {
    let tmp = document.getElementById(id.concat("More"))
    let tmp2 = document.getElementById(id.concat("Less"))
    if(type === "More") {
        tmp.className += " ExamsByPacksPackBoxWhiteBoxDescriptionHidden"
        tmp2.className = tmp2.className.replace(" ExamsByPacksPackBoxWhiteBoxDescriptionHidden", "");
    } else {
        tmp.className = tmp.className.replace(" ExamsByPacksPackBoxWhiteBoxDescriptionHidden", "");
        tmp2.className += " ExamsByPacksPackBoxWhiteBoxDescriptionHidden"
    }
  }

  function changeExamPage(num) {
    setPage(num+Page)
    let tmp = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot")
    for(let i=0;i<tmp.length;i++) {
      if(tmp[i].className.includes("ActiveDot")) {
        tmp[i].className = tmp[i].className.replace(" ActiveDot", "");
      }
    }
    let tmp2 = document.getElementById('pageDot'.concat(num+Page))
    tmp2.className += " ActiveDot"
  }

  function ChangeNumberOfExams() {
    let current = ExamsShown
    let current2 = PacksShown
    let different = false
    let different2 = false

    if(window.innerWidth <= 420) {
      setExamsShown(1)
      if(current != 1) different = true
    } else if(window.innerWidth <= 720) {
      setExamsShown(2)
      if(current != 2) different = true
    } else {
      setExamsShown(4)
      if(current != 4) different = true
    }

    if(window.innerWidth <= 620) {
      setPacksShown(1)
      if(current2 != 1) different2 = true
    } else if(window.innerWidth <= 920) {
      setPacksShown(2)
      if(current2 != 2) different2 = true
    } else {
      setPacksShown(3)
      if(current2 != 3) different2 = true
    }

    if(different) {
      setPage(1)
      let tmp = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot")
      for(let i=0;i<tmp.length;i++) {
        if(tmp[i].className.includes("ActiveDot")) {
          tmp[i].className = tmp[i].className.replace(" ActiveDot", "");
        }
      }
      let tmp2 = document.getElementById('pageDot'.concat(1))
      if (tmp2) tmp2.className += " ActiveDot"
    }

    if(different2) {
      setPackPage(1)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", ChangeNumberOfExams);
    return () => window.removeEventListener("resize", ChangeNumberOfExams);
  }, [ChangeNumberOfExams]);
  
    return (
      <>
      {VisualizePack === null || VisualizePack.length === 2 ?
      null :
      <>
      <div className="ExamChoiceByGenderAllOuter">
     <div className="ExamChoiceByGenderAll" id="ManageAll">
      <div className="ExamChoiceByGenderLeftSide">
        <div className="ExamChoiceByGenderLeftSideUpperBlock">
        <div className="ExamChoiceByGenderPackBoxImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/packs/"+ VisualizePack +".png')",backgroundPosition: 'center',backgroundPositionY: 'top',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}><div className="ExamChoiceByGenderPackBoxImageGradient"></div></div>
        <div className="ExamChoiceByGenderPackTextContainter">
          <h2 className="ExamChoiceByGenderPackTitle">{packslist[VisualizePack].name}</h2>
          <div className="ExamChoiceByGenderPackDescription">{packslist[VisualizePack].description}</div>
          <div className="ExamChoiceByGenderPackProWarning">IMPORTANTE: Agregar exámenes PRO a la orden médica incurre costos adicionales.</div>
          <div className='ExamChoiceByGenderPackPriceFlex'>
            {ProPrice === 0 ?
            <div className='ExamChoiceByGenderPackPriceFree' id="PriceDiv">Gratis</div>
            :
            <div className='ExamChoiceByGenderPackPrice' id="PriceDiv">${ProPrice}</div>
}
            {BasketPacks.includes(VisualizePack) ?
            <div className='ExamChoiceByGenderPackAddToOrderButton'  
                onClick={() => {ModifyPackCheckout(VisualizePack); MySwal.fire({
                position: "top-end",
                icon: "success",
                iconColor: "red",
                title: "Se ha eliminado el pack de su orden médica",
                showConfirmButton: false,
                timer: 1500
              });}}>Eliminar de Orden Médica</div>
            : ChosenExams.length === 0 ? 
            <div className='ExamChoiceByGenderPackAddToOrderButtonDisabled'>Añadir a Orden Médica</div>
            :
            <div className='ExamChoiceByGenderPackAddToOrderButton' 
                onClick={() => {ModifyPackCheckout(VisualizePack); MySwal.fire({
                title: "Se ha añadido el pack a su orden médica",
                confirmButtonText: "Genera tu órden médica"
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  Navigate("/orden-medica");
                }
              });}}>Añadir a Orden Médica</div>
}
          </div>
        </div>
        </div>
        <div className="ExamChoiceByGenderChoiceCalculatorBlock">
          <img className="ExamChoiceByGenderChoiceCalculatorBlockExamIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleRealHeartIcon.png"/>
          <div className="ExamChoiceByGenderChoiceCalculatorBlockUpTitle">Mes del corazón</div>
          <h4 className="ExamChoiceByGenderChoiceCalculatorBlockTitle">¿Cuál es la edad de tu corazón? </h4>
          <div className="ExamChoiceByGenderChoiceCalculatorBlockSubtitle">La edad vascular te da una idea de la salud de tu corazón. Para que puedas conocerla hemos habilitado una calculadora para averiguarlo en cuestión de minutos. Funciona comparando tu edad real con tu edad cardíaca haciéndote preguntas sobre tu salud.</div>
          <div className="ExamChoiceByGenderChoiceCalculatorBlockInnerPackButtonFlex" onClick={() =>{Navigate("/edad-del-corazon"); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
              <div className="ExamChoiceByGenderChoiceCalculatorBlockInnerPackButtonText">Descúbrelo&ensp;</div>
              <img className="ExamChoiceByGenderChoiceCalculatorBlockInnerPackButtonIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
          </div>
        </div>
      </div>
      <div className="ExamChoiceByGenderRightSide">
      {packslist[VisualizePack].depends & packslist[VisualizePack].gender === "Mixed" ?
        <div className="ExamChoiceByGenderUpperTags">
          {Gender === 'F' ?
          <>
          <div className="ExamChoiceByGenderFemaleTag ExamChoiceByGenderActiveTag" id="FemaleTab">Mujer</div>
          <div className="ExamChoiceByGenderMaleTag" id="MaleTab" onClick={() => toggleBack(true)}>Hombre</div>
          </>
          :
          <>
          <div className="ExamChoiceByGenderFemaleTag ExamChoiceByGenderActiveTag" id="FemaleTab" onClick={() => toggleBack(false)}>Mujer</div>
          <div className="ExamChoiceByGenderMaleTag" id="MaleTab">Hombre</div>
          </>
      }
        </div>
        : packslist[VisualizePack].depends & packslist[VisualizePack].gender === "F" ?
          <div className="ExamChoiceByGenderUpperTags">
            <div className="ExamChoiceByGenderFemaleTag ExamChoiceByGenderActiveTag" id="FemaleTab">Mujer</div>
          </div>
        : packslist[VisualizePack].depends & packslist[VisualizePack].gender === "M" ?
          <div className="ExamChoiceByGenderUpperTags">
            <div className="ExamChoiceByGenderMaleTag ExamChoiceByGenderActiveTag" id="MaleTab">Hombre</div>
          </div>
        : 
          <div className="ExamChoiceByGenderUpperTags">
            <div className="ExamChoiceByGenderMaleTag ExamChoiceByGenderActiveTag" id="MaleTab">Hombres y Mujeres</div>
          </div>
        }
        <div className="ExamChoiceByGenderChoiceContainer">
        {/*packslist[VisualizePack].depends & packslist[VisualizePack].gender === "Mixed" ?
        <>
          {Gender === 'F' ?
          <div className="ExamChoiceByGenderChoiceRightText">
            Exámenes Preventivos / Mujer
          </div>
          :
          <div className="ExamChoiceByGenderChoiceRightText">
            Exámenes Preventivos / Hombre
          </div>
          }
        </>
        : packslist[VisualizePack].depends & packslist[VisualizePack].gender === "F" ?
          <div className="ExamChoiceByGenderChoiceRightText">
            Exámenes Preventivos / Mujer
          </div>
        : packslist[VisualizePack].depends & packslist[VisualizePack].gender === "M" ?
          <div className="ExamChoiceByGenderChoiceRightText">
            Exámenes Preventivos / Hombre
          </div>
        :
          <div className="ExamChoiceByGenderChoiceRightText">
            Exámenes Preventivos / Mixto
          </div>
        */}
          <div className="ExamChoiceByGenderChoiceDescription">
            Un chequeo preventivo te permitirá conocer tu estado de salud para evitar aparición de enfermedades y abordaje temprano de posibles síntomas.
            Escoge aquí los exámenes del pack que te interesan.
          </div>
          <div className="ExamChoiceByGenderChoiceChooseAllDiv">
            <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceChooseAllInput" id={'ChooseAll'} onClick={() => handleChosen('ChooseAll')}/>
            <span className="ExamChoiceByGenderChoiceChooseAllText">Elegir todos</span>
          </div>
          {packslist[VisualizePack].depends ?
          <>
          {packslist[VisualizePack][Gender].exams.map(a => examslist[a].emission).includes('Laboratorio') | packslist[VisualizePack][Gender].examsPro.map(a => examslist[a].emission).includes('Laboratorio') ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Laboratorio
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packslist[VisualizePack][Gender].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Laboratorio" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)}/>
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {[...Array(packslist[VisualizePack][Gender].examsPro.length).keys()].map((key) => (
              <div key={key}>
              {examslist[packslist[VisualizePack][Gender].examsPro[key]].emission === "Laboratorio" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packslist[VisualizePack][Gender].examsPro[key]} onClick={() => handleChosen(packslist[VisualizePack][Gender].examsPro[key])}/>
                <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packslist[VisualizePack][Gender].examsPro[key]].name}</span>
                {examslist[packslist[VisualizePack][Gender].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packslist[VisualizePack][Gender].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packslist[VisualizePack][Gender].examsPro[key]].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div> 
          </>
          : null}
          {others.some((el) => packslist[VisualizePack][Gender].exams.map(a => examslist[a].emission).includes(el)) | others.some((el) => packslist[VisualizePack][Gender].examsPro.map(a => examslist[a].emission).includes(el)) ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Imagenología y Procedimientos
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packslist[VisualizePack][Gender].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Imagenología" | examslist[key].emission === "Procedimientos" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {[...Array(packslist[VisualizePack][Gender].examsPro.length).keys()].map((key) => (
              <div key={key}>
              {examslist[packslist[VisualizePack][Gender].examsPro[key]].emission === "Imagenología" | examslist[packslist[VisualizePack][Gender].examsPro[key]].emission === "Procedimientos" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packslist[VisualizePack][Gender].examsPro[key]} onClick={() => handleChosen(packslist[VisualizePack][Gender].examsPro[key])}/>
                <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packslist[VisualizePack][Gender].examsPro[key]].name}</span>
                {examslist[packslist[VisualizePack][Gender].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packslist[VisualizePack][Gender].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packslist[VisualizePack][Gender].examsPro[key]].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div>
          </>
          : null}
          {packslist[VisualizePack][Gender].exams.map(a => examslist[a].emission).includes('Derivaciones') | packslist[VisualizePack][Gender].examsPro.map(a => examslist[a].emission).includes('Derivaciones') ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Derivaciones
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packslist[VisualizePack][Gender].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Derivaciones" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {[...Array(packslist[VisualizePack][Gender].examsPro.length).keys()].map((key) => (
              <div key={key}>
              {examslist[packslist[VisualizePack][Gender].examsPro[key]].emission === "Derivaciones" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packslist[VisualizePack][Gender].examsPro[key]} onClick={() => handleChosen(packslist[VisualizePack][Gender].examsPro[key])}/>
                <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packslist[VisualizePack][Gender].examsPro[key]].name}</span>
                {examslist[packslist[VisualizePack][Gender].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packslist[VisualizePack][Gender].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packslist[VisualizePack][Gender].examsPro[key]].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div>
          </>
          : null}
          {/*packslist[VisualizePack][Gender].exams.map(a => examslist[a].emission).includes("") ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Otros
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packslist[VisualizePack][Gender].exams.filter(a => examslist[a].emission === "").map((key) => (
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                <div className="ExamChoiceByGenderChoiceExamInfo">?
                  <span className="ExamChoiceByGenderChoiceTooltip">{examslist[key].additional}</span>
                </div>
                }
              </div>
              </>
            ))}
            </div>
          </>
          : null
*/}
          </>
          
        : 
          <>
          {packslist[VisualizePack].exams.map(a => examslist[a].emission).includes('Laboratorio') | packslist[VisualizePack].examsPro.map(a => examslist[a].emission).includes('Laboratorio') ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Laboratorio
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {VisualizePack === "PCM00YMC008" ?
            <>
            {packslist[VisualizePack].exams.filter(a => !["YMC008","YMC009","YMC043"].includes(a)).map((key) => (
              <div key={key}>
              {examslist[key].emission === "Laboratorio" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)}/>
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={"PCM00YMC008"} onClick={() => handleChosen("PCM00YMC008")}/>
                <span className="ExamChoiceByGenderChoiceExamGroupName">&nbsp;Pruebas Tiroideas</span>
              </div>
              {packslist[VisualizePack].exams.filter(a => ["YMC008","YMC009","YMC043"].includes(a)).map((key) => (
                <div key={key}>
                <div className="ExamChoiceByGenderChoiceExamsFlexed">
                  <span className="ExamChoiceByGenderChoiceExamName">&emsp; {examslist[key].name}</span>
                  {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </div>
              ))}
            </>
            :
            <>
            {packslist[VisualizePack].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Laboratorio" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)}/>
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </>}
            {VisualizePack === "PDM00YMC005" ?
            <>
             <div className="ExamChoiceByGenderChoiceExamsFlexed">
                  <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packslist[VisualizePack].examsPro[2]} onClick={() => handleChosen(packslist[VisualizePack].examsPro[2])}/>
                  <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packslist[VisualizePack].examsPro[2]].name}</span>
                  {examslist[packslist[VisualizePack].examsPro[2]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(2))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packslist[VisualizePack].pricesPro[2]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(2)}>{examslist[packslist[VisualizePack].examsPro[2]].additional}</div>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={"PDM00YMC005"} onClick={() => handleChosen("PDM00YMC005")}/>
                <span className="ExamChoiceByGenderChoiceExamGroupName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span>&nbsp;Panel ETS por PCR en orina</span>
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packslist[VisualizePack].pricesPro[0]})</div>
              </div>
              {[...Array(packslist[VisualizePack].examsPro.length-1).keys()].map((key) => (
                <div key={key}>
                <div className="ExamChoiceByGenderChoiceExamsFlexed">
                  <span className="ExamChoiceByGenderChoiceExamName">&emsp;<span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packslist[VisualizePack].examsPro[key]].name}</span>
                  {examslist[packslist[VisualizePack].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packslist[VisualizePack].examsPro[key]].additional}</div>
              </div>
              ))}
            </>
            :
            <>
            {packslist[VisualizePack].examsPro.length !== 0 ?
            <>
              {[...Array(packslist[VisualizePack].examsPro.length).keys()].map((key) => (
                <div key={key}>
                {examslist[packslist[VisualizePack].examsPro[key]].emission === "Laboratorio" ?
                <>
                <div className="ExamChoiceByGenderChoiceExamsFlexed">
                  <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packslist[VisualizePack].examsPro[key]} onClick={() => handleChosen(packslist[VisualizePack].examsPro[key])}/>
                  <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packslist[VisualizePack].examsPro[key]].name}</span>
                  {examslist[packslist[VisualizePack].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packslist[VisualizePack].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packslist[VisualizePack].examsPro[key]].additional}</div>
              </>
                :
                null}
                </div>
              ))}
              </>: null}
              </>}
            </div> 
          </>
          : null}
          {others.some((el) => packslist[VisualizePack].exams.map(a => examslist[a].emission).includes(el)) | others.some((el) => packslist[VisualizePack].examsPro.map(a => examslist[a].emission).includes(el)) ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Imagenología y Procedimientos
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packslist[VisualizePack].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Imagenología" | examslist[key].emission === "Procedimientos" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {[...Array(packslist[VisualizePack].examsPro.length).keys()].map((key) => (
              <div key={key}>
              {examslist[packslist[VisualizePack].examsPro[key]].emission === "Imagenología" | examslist[packslist[VisualizePack].examsPro[key]].emission === "Procedimientos" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packslist[VisualizePack].examsPro[key]} onClick={() => handleChosen(packslist[VisualizePack].examsPro[key])} />
                <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packslist[VisualizePack].examsPro[key]].name}</span>
                {examslist[packslist[VisualizePack].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packslist[VisualizePack].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packslist[VisualizePack].examsPro[key]].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div>
          </>
          : null}
          {packslist[VisualizePack].exams.map(a => examslist[a].emission).includes('Derivaciones') | packslist[VisualizePack].examsPro.map(a => examslist[a].emission).includes('Derivaciones') ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Derivaciones
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packslist[VisualizePack].exams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Derivaciones" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            {[...Array(packslist[VisualizePack].examsPro.length).keys()].map((key) => (
              <div key={key}>
              {examslist[packslist[VisualizePack].examsPro[key]].emission === "Derivaciones" ?
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={packslist[VisualizePack].examsPro[key]} onClick={() => handleChosen(packslist[VisualizePack].examsPro[key])} />
                <span className="ExamChoiceByGenderChoiceExamName"><span className="ExamChoiceByGenderChoiceExamPRO">PRO</span> {examslist[packslist[VisualizePack].examsPro[key]].name}</span>
                {examslist[packslist[VisualizePack].examsPro[key]].additional === "" ?
                null :
                  <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
                <div className="ExamChoiceByGenderChoiceExamPrice">(+${packslist[VisualizePack].pricesPro[key]})</div>
              </div>
              <div className="ExamChoiceByGenderChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[packslist[VisualizePack].examsPro[key]].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div>
          </>
          : null}
          {/*packslist[VisualizePack].exams.map(a => examslist[a].emission).includes("") ?
          <>
            <div className="ExamChoiceByGenderChoiceLabel">
              Otros
            </div>
            <div className="ExamChoiceByGenderChoiceExams">
            {packslist[VisualizePack].exams.filter(a => examslist[a].emission === "").map((key) => (
              <>
              <div className="ExamChoiceByGenderChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByGenderChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByGenderChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                <div className="ExamChoiceByGenderChoiceExamInfo">?
                  <span className="ExamChoiceByGenderChoiceTooltip">{examslist[key].additional}</span>
                </div>
                }
              </div>
              </>
            ))}
            </div>
          </>
          : null */}
          </> }
        </div>
      </div>
     </div>

     {packslist[VisualizePack].depends ?
     <div className='ExamChoiceByGenderAllExamsDivContainer'>
     <div className='ExamChoiceByGenderAllExamsDiv'>
       <div>Exámenes:&ensp; ({[...packslist[VisualizePack][Gender].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packslist[VisualizePack][Gender].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].length}) &nbsp;
         <img className="ExamChoiceByGenderAllExamsPageIcon" alt='' src={TinyPageIcon} />
       </div>
       <div className="ExamChoiceByGenderAllExamsHorizontalLine"></div>
         <div className="ExamChoiceByGenderAllExamsFlexFullLine">
           {Page === 1 ?
           null :
           <div className='ExamChoiceByGenderAllExamsLeftArrowDiv' onClick={() => changeExamPage(-1)}>
             <FontAwesomeIcon 
               icon={faArrowRightLong} 
               className='ExamChoiceByGenderAllExamsLeftArrow text-end fa-rotate-180'
             />
           </div>
           }
           <div className="ExamChoiceByGenderAllExamsFlexExamLine">
             {[...packslist[VisualizePack][Gender].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packslist[VisualizePack][Gender].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].slice(ExamsShown*(Page-1),ExamsShown*Page).map((exam) => (
             <div className="ExamChoiceByGenderAllExamsSingleExamBlock" key={exam}>
               <div className="ExamChoiceByGenderAllExamsSingleExamBlockUpLine">
                 <img className="ExamChoiceByGenderAllExamsExamIcon" alt='' src={TinyExamIcon} />&ensp;
                 <div className="ExamChoiceByGenderAllExamsSingleExamBlockName">{examslist[exam].name}</div>
               </div>
               <div className="ExamChoiceByGenderAllExamsSingleExamBlockDescr">{examslist[exam].description}</div>
             </div>
             ))}
           </div>
           {Page === Math.ceil([...packslist[VisualizePack][Gender].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packslist[VisualizePack][Gender].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].length / ExamsShown) ?
           null :
           <div className='ExamChoiceByGenderAllExamsRightArrowDiv' onClick={() => changeExamPage(1)}>
             <FontAwesomeIcon 
               icon={faArrowRightLong} 
               className='ExamChoiceByGenderAllExamsRightArrow text-end'
             />
           </div>
           }
         </div>
         <div className='ExamChoiceByGenderAllExamsDotsDiv'>
           <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot ActiveDot" id="pageDot1"></div>
           {[...Array(Math.ceil([...packslist[VisualizePack][Gender].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packslist[VisualizePack][Gender].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].length / ExamsShown)-1).keys()].map((dots) => (
             <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot" key={'pageDot'.concat(dots+2)} id={'pageDot'.concat(dots+2)}></div>
           ))}
         </div>
         {[...packslist[VisualizePack][Gender].exams.filter(obj => examslist[obj].emission === "Derivaciones"),...packslist[VisualizePack][Gender].examsPro.filter(obj => examslist[obj].emission === "Derivaciones")].length !== 0 ?
         <>
         <div className="ExamChoiceByGenderAllExamsDerivations">
          <div>Derivaciones:&ensp; ({[...packslist[VisualizePack][Gender].exams.filter(obj => examslist[obj].emission === "Derivaciones"),...packslist[VisualizePack][Gender].examsPro.filter(obj => examslist[obj].emission === "Derivaciones")].length}) &nbsp;
          <img className="ExamChoiceByGenderAllExamsPageIcon" alt='' src={TinyPageIcon} />
        </div>
        <div className="ExamChoiceByGenderAllExamsHorizontalLine"></div>
          <div className="ExamChoiceByGenderAllExamsFlexFullLine">
            <div className="ExamChoiceByGenderAllDerivationsFlexExamLine">
              {[...packslist[VisualizePack][Gender].exams.filter(obj => examslist[obj].emission === "Derivaciones"),...packslist[VisualizePack][Gender].examsPro.filter(obj => examslist[obj].emission === "Derivaciones")].map((exam) => (
              <div className="ExamChoiceByGenderAllDerivationsSingleExamBlock" key={exam}>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockUpLine">
                  <img className="ExamChoiceByGenderAllExamsExamIcon" alt='' src={TinyExamIcon} />&ensp;
                  <div className="ExamChoiceByGenderAllExamsSingleExamBlockName">{examslist[exam].name}</div>
                </div>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockDescr">{examslist[exam].description}</div>
              </div>
              ))}
            </div>
          </div>
        </div>
        </>
        : null}
     </div>
    </div>
     :
     <div className='ExamChoiceByGenderAllExamsDivContainer'>
      <div className='ExamChoiceByGenderAllExamsDiv'>
        { [...packslist[VisualizePack].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packslist[VisualizePack].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].length === 0 ?
        null :
        <>
        <h5 className="ExamChoiceByGenderAllExamsPageMainTitle">Exámenes:&ensp; ({[...packslist[VisualizePack].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packslist[VisualizePack].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].length}) &nbsp;
          <img className="ExamChoiceByGenderAllExamsPageIcon" alt='' src={TinyPageIcon} />
        </h5>
        <div className="ExamChoiceByGenderAllExamsHorizontalLine"></div>
          <div className="ExamChoiceByGenderAllExamsFlexFullLine">
            {Page === 1 ?
            null :
            <div className='ExamChoiceByGenderAllExamsLeftArrowDiv' onClick={() => changeExamPage(-1)}>
              <FontAwesomeIcon 
                icon={faArrowRightLong} 
                className='ExamChoiceByGenderAllExamsLeftArrow text-end fa-rotate-180'
              />
            </div>
            }
            <div className="ExamChoiceByGenderAllExamsFlexExamLine">
              {[...packslist[VisualizePack].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packslist[VisualizePack].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].slice(ExamsShown*(Page-1),ExamsShown*Page).map((exam) => (
              <div className="ExamChoiceByGenderAllExamsSingleExamBlock" key={exam}>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockUpLine">
                  <img className="ExamChoiceByGenderAllExamsExamIcon" alt='' src={TinyExamIcon} />&ensp;
                  <div className="ExamChoiceByGenderAllExamsSingleExamBlockName">{examslist[exam].name}</div>
                </div>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockDescr">{examslist[exam].description}</div>
              </div>
              ))}
            </div>
            {Page === Math.ceil([...packslist[VisualizePack].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packslist[VisualizePack].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].length / ExamsShown) ?
            null :
            <div className='ExamChoiceByGenderAllExamsRightArrowDiv' onClick={() => changeExamPage(1)}>
              <FontAwesomeIcon 
                icon={faArrowRightLong} 
                className='ExamChoiceByGenderAllExamsRightArrow text-end'
              />
            </div>
            }
          </div>
          <div className='ExamChoiceByGenderAllExamsDotsDiv'>
            <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot ActiveDot" id="pageDot1"></div>
            {[...Array(Math.ceil([...packslist[VisualizePack].exams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== ""),...packslist[VisualizePack].examsPro.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "")].length / ExamsShown)-1).keys()].map((dots) => (
              <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot" key={'pageDot'.concat(dots+2)} id={'pageDot'.concat(dots+2)}></div>
            ))}
          </div>
          </>}
          {[...packslist[VisualizePack].exams.filter(obj => examslist[obj].emission === "Derivaciones"),...packslist[VisualizePack].examsPro.filter(obj => examslist[obj].emission === "Derivaciones")].length !== 0 ?
         <>
        <div className="ExamChoiceByGenderAllExamsDerivations">
          <h5 className="ExamChoiceByGenderAllExamsPageMainTitle">Derivaciones:&ensp; ({[...packslist[VisualizePack].exams.filter(obj => examslist[obj].emission === "Derivaciones"),...packslist[VisualizePack].examsPro.filter(obj => examslist[obj].emission === "Derivaciones")].length}) &nbsp;
          <img className="ExamChoiceByGenderAllExamsPageIcon" alt='' src={TinyPageIcon} />
        </h5>
        <div className="ExamChoiceByGenderAllExamsHorizontalLine"></div>
          <div className="ExamChoiceByGenderAllExamsFlexFullLine">
            <div className="ExamChoiceByGenderAllDerivationsFlexExamLine">
              {[...packslist[VisualizePack].exams.filter(obj => examslist[obj].emission === "Derivaciones"),...packslist[VisualizePack].examsPro.filter(obj => examslist[obj].emission === "Derivaciones")].map((exam) => (
              <div className="ExamChoiceByGenderAllDerivationsSingleExamBlock" key={exam}>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockUpLine">
                  <img className="ExamChoiceByGenderAllExamsExamIcon" alt='' src={TinyExamIcon} />&ensp;
                  <div className="ExamChoiceByGenderAllExamsSingleExamBlockName">{examslist[exam].name}</div>
                </div>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockDescr">{examslist[exam].description}</div>
              </div>
              ))}
            </div>
          </div>
        </div>
        </> : null}
      </div>
     </div>
      }

     {packslist[VisualizePack].recommended ?
      <div className="ExamChoiceByGenderPacksDiv">
            <div className="ExamChoiceByGenderPacksTitle">Complementa tu chequeo con alguna de estas sugerencias</div>
            <div className="ExamChoiceByGenderPacksPackBoxesFullLineFlex">
              {PackPage === 1 ?
              null :
              <div className='ExamChoiceByGenderPacksLeftArrowDiv' onClick={() => setPackPage(PackPage-1)}>
                <FontAwesomeIcon 
                    icon={faArrowRightLong} 
                    className='ExamChoiceByGenderPacksLeftArrow text-end fa-rotate-180'
                />
              </div>
              }

              <div className="ExamChoiceByGenderPacksPackBoxesFlex">
                  {packslist[VisualizePack].recommended.slice(PacksShown*(PackPage-1),PacksShown*PackPage).map((pack) => (
                  <div className="ExamChoiceByGenderPacksPackBox" key={pack}>
                    <div className='ExamsByPacksPackBoxBackImage' style={{backgroundImage:"linear-gradient(0deg, rgba(207,249,255,1) 0%, rgba(255,255,255,0) 50%),url('https://static.yomecontrolo.cl/public/img/packs/"+ pack +".png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
                      <div className="ExamChoiceByGenderPacksPackBoxWhiteBox">
                          <div className="ExamChoiceByGenderPacksPackBoxWhiteBoxTitle">{packslist[pack].name}</div>
                          <div className="ExamsByPacksPackBoxWhiteBoxDescription" id={pack.concat("More")}>{packslist[pack].description.split(' ').slice(0,12).join(' ')} &nbsp;
                              <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"More")}>Leer más...</span>
                          </div>
                          <div className="ExamsByPacksPackBoxWhiteBoxDescription ExamsByPacksPackBoxWhiteBoxDescriptionHidden" id={pack.concat("Less")}>{packslist[pack].description} &nbsp;
                              <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"Less")}>Leer menos...</span>
                          </div>
                          <div className="ExamChoiceByGenderPacksPackBoxWhiteBoxFlex">
                              <div className="ExamChoiceByGenderPacksPackBoxWhiteBoxPrice">${packslist[pack].price}</div>
                          </div>
                          <div className="ExamChoiceByGenderPackBoxWhiteBoxMoreInfoButton" onClick={() => { document.body.scrollTop = 0; document.documentElement.scrollTop = 0; ChangePack(pack);}}>Más Información</div>
                      </div>
                  </div>
                  ))}
              </div>

              {PackPage === Math.ceil(packslist[VisualizePack].recommended.length / PacksShown) ?
              null :
              <div className='ExamChoiceByGenderPacksRightArrowDiv' onClick={() => setPackPage(PackPage+1)}>
                <FontAwesomeIcon 
                    icon={faArrowRightLong} 
                    className='ExamChoiceByGenderPacksRightArrow text-end'
                />
              </div>
              }

            </div>
        </div>
        : null}

        </div>
        <MakeItEasy/>
        <div className='ScrollBackToTopOuter ScrollBackToTopHidden' id="ToTheTop">
            <div className='ScrollBackToTop' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
            <FontAwesomeIcon 
                icon={faUpLong} 
                className='ScrollBackToTopUpArrow'
            />
            </div>
        </div>
        </>
        }
        </>
    );
}

export default ExamChoiceByGenderRoute;