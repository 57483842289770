import React, { useState, useEffect, useContext } from 'react';
import "../../Styles/HeartAgeCalculator.css";
import { useNavigate } from 'react-router-dom';
import HeartAgeCalc from './HeartAgeCode'
import { Packs } from '../Context/PacksContext';
import { UserInfo } from "../Context/UserInfoContext";
import Newsletter from "../ContactRoute/Newsletter"
import packslist from "../../../JSONS/updatedPacks.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

function HeartAgeCalculator() {
  const Navigate = useNavigate();

  const executeScroll = () => document.getElementById('results-ref').scrollIntoView();

  const executeScroll2 = () => document.getElementById('recalculate-ref').scrollIntoView();

  const [ArrowUp, setArrowUp] = useState(false)
  const [Age,setAge] = useState(40)
  const [Smoke,setSmoke] = useState("")
  const [Sistolic,setSistolic] = useState("")
  const [TrueSistolic,setTrueSistolic] = useState("")
  const [Choles,setCholes] = useState("")
  const [TrueCholes,setTrueCholes] = useState("")
  const [TrueIMC,setTrueIMC] = useState([])
  const [Email,setEmail] = useState("")
  const [Calculated, setCalculated] = useState(false)
  const [Risk, setRisk] = useState(0)
  const [Risk10, setRisk10] = useState(0)
  const [TmpAge,setTmpAge] = useState(40)
  const [OneTime, setOneTime] = useState(false)
  const [ToLipidic, setToLipidic] = useState(false)
  const [LongText, setLongText] = useState(false)
  const [TaskStart, setTaskStart] = useState(false)
  const [TaskDone, setTaskDone] = useState(false)
  const [WOSmoke,setWOSmoke] = useState(-1)
  const [RegCholes,setRegCholes] = useState(-1)
  const [RegSistolic,setRegSistolic] = useState(-1)

  const {
    HeartAgeCalculated, setHeartAgeCalculated, Weight, setWeight, Height, setHeight, Recent, setRecent, Gender, setGender
  } = useContext(UserInfo);

  useEffect(() => {
    if(ToLipidic) {
        window.scrollTo(0, 0);
        Navigate("/detalles-de-pack?pack=PM00YMC041")
    }
  })

  const [validation, setvalidation] = useState({
    "Gender": "valid",
    "Smoke": "valid",
    "Blood": "valid",
    "Choles": "valid",
    "Weight": "valid",
    "Height": "valid"
  });

  function GenderChange(sex) {
    let tmp1 = document.getElementById("MaleTile")
    let tmp1I = document.getElementById("MaleTileIcon")
    let tmp2 = document.getElementById("FemaleTile")
    let tmp2I = document.getElementById("FemaleTileIcon")
    if(sex !== "F") {
        if(tmp2.className.includes("HeartAgeCalculatorRightSideSurveyQ1ActiveTile")) {
            tmp2.className = tmp2.className.replace(" HeartAgeCalculatorRightSideSurveyQ1ActiveTile","")
            tmp2I.className = tmp2I.className.replace(" HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon","")
        }
        tmp1.className += " HeartAgeCalculatorRightSideSurveyQ1ActiveTile"
        tmp1I.className += " HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon"
        setGender(1)
    }
    if(sex !== "M") {
        if(tmp1.className.includes("HeartAgeCalculatorRightSideSurveyQ1ActiveTile")) {
            tmp1.className = tmp1.className.replace(" HeartAgeCalculatorRightSideSurveyQ1ActiveTile","")
            tmp1I.className = tmp1I.className.replace(" HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon","")
        }
        tmp2.className += " HeartAgeCalculatorRightSideSurveyQ1ActiveTile"
        tmp2I.className += " HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon"
        setGender(2)
    }
  }

  function SmokeChange(ans) {
    let tmp1 = document.getElementById("NoSmokeTile")
    let tmp2 = document.getElementById("SmokeTile")
    if(ans === "NO") {
        if(tmp2.className.includes("HeartAgeCalculatorRightSideSurveyQ1ActiveTile")) tmp2.className = tmp2.className.replace(" HeartAgeCalculatorRightSideSurveyQ1ActiveTile","")
        tmp1.className += " HeartAgeCalculatorRightSideSurveyQ1ActiveTile"
        setSmoke("NO")
    }
    if(ans === "YES") {
        if(tmp1.className.includes("HeartAgeCalculatorRightSideSurveyQ1ActiveTile")) tmp1.className = tmp1.className.replace(" HeartAgeCalculatorRightSideSurveyQ1ActiveTile","")
        tmp2.className += " HeartAgeCalculatorRightSideSurveyQ1ActiveTile"
        setSmoke("YES")
    }
  }

  const handleChange = (value, setValue) => {
    const result = value.replace(/\D/g, '');
    setValue(result.slice(0, 3));
  };

  function HandleSubmit() {

    let isGenderValid = Gender !== 1 && Gender !== 2 ? "invalid" : "valid";
    let isSmokeValid = Smoke !== "NO" && Smoke !== "YES" ? "invalid" : "valid";
    let isCholesValid = Choles.trim() === "" ? "invalid" : Choles >= 550 ? "invalid" : "valid";
    let isBloodValid = Sistolic.trim() === "" ? "invalid" : Sistolic < 40 ? "invalid" : Sistolic > 370 ? "invalid" : "valid";
    let isWeightValid = Weight.trim() === "" ? "invalid" : "valid";
    let isHeightValid = Height.trim() === "" ? "invalid" : "valid";

    if(Weight === "" || Weight < 2 || Weight > 650) {
        isWeightValid = "invalid"
      }
    if(Height === "" || Height < 54 || Height > 272) {
        isHeightValid = "invalid"
    }

    setvalidation({
        "Gender": isGenderValid,
        "Smoke": isSmokeValid,
        "Blood": isBloodValid,
        "Choles": isCholesValid,
        "Weight": isWeightValid,
        "Height": isHeightValid
    });
    let answers = [isGenderValid,isSmokeValid,isCholesValid,isBloodValid,isWeightValid,isHeightValid]

    if(!answers.includes("invalid")) {
        getHeartAge()
    }
  }

  async function getHeartAge() {
    let gender = Gender === 1 ? "M" : "F"
    let res = await HeartAgeCalc(gender, Age, Smoke, Sistolic, Choles)
    setRisk(res)
    let res10 = await HeartAgeCalc(gender, Age+10, Smoke, Sistolic, Choles)
    setRisk10(res10)
    //console.log(res)
    setTmpAge(Age)
    setTrueSistolic(Sistolic)
    setTrueCholes(Choles)

    if(Smoke === "YES") {
        let tmp = await HeartAgeCalc(gender, Age, "NO", Sistolic, Choles)
        setWOSmoke(tmp.heartAge)
    } else setWOSmoke(-1)

    if(Sistolic > 120) {
        let tmp = await HeartAgeCalc(gender, Age, Smoke, 120, Choles)
        setRegSistolic(tmp.heartAge)
    } else setRegSistolic(-1)
    
    if(Choles > 154) {
        let tmp = await HeartAgeCalc(gender, Age, Smoke, Sistolic, 154)
        setRegCholes(tmp.heartAge)
    } else setRegCholes(-1)

    getIMC()
    setCalculated(true)
    setOneTime(true)
    if(!HeartAgeCalculated){
        uploadHeartAge(res.heartAge)
        setHeartAgeCalculated(true)
    }
  }

    function getIMC() {
        let IMC = Weight/(Height/100)**2
        let cats = ["Bajo", "Saludable", "Sobrepeso", "Obesidad I", "Obesidad II", "Obesidad III"]
        let which = 0
        let ideal = [0,0]
        if(IMC < 18.5) {
            which = 0
        } else if(IMC < 25 & IMC >= 18.5) {
            which = 1
        } else if(IMC < 30 & IMC >= 25) {
            which = 2
        } else if(IMC < 35 & IMC >= 30) {
            which = 3
        } else if(IMC < 40 & IMC >= 35) {
            which = 4
        } else {
            which = 5
        }
        ideal[0] = ((Height/100)**2) * 18.5
        ideal[1] = ((Height/100)**2) * 25
        setTrueIMC([IMC, cats[which], parseInt(ideal[0]), parseInt(ideal[1]), Weight])
    }

    useEffect(() => {
        if(OneTime) {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            setOneTime(false)
        }
    })

    function toggleAdditional(id) {
        let tmp = document.getElementById(id)
        if(tmp.className.includes("InformationCollapsed")) {
            tmp.className = tmp.className.replace(" InformationCollapsed", "");
        } else {
            tmp.className += " InformationCollapsed"
        }
    }

    const uploadHeartAge = async(res) => {
        setTaskStart(true)
    
        var params = {
            gender: Gender,
            age: Age,
            smoke: Smoke,
            systolic: parseInt(Sistolic),
            choles: parseInt(Choles),
            height: parseInt(Height),
            weight: parseInt(Weight),
            heart_age: res,
        };
      
        var requestOptions = {
          method: 'POST',
          redirect: 'follow',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params)
        };
    
        try{
          
        const resp = await fetch("https://ymc-doc-api.azurewebsites.net/heartageregister", requestOptions)
         if (resp.status !== 200){
          alert("there has been some error");
          return false;
         }
    
         setTaskDone(true)
         setTaskStart(false)
        }
        catch(error){
          //console.log("there has been an error login in")
          alert("Ocurrió un error procesando los resultados")
          setTaskStart(false)
          return false
        }
    }

    function displayBiblio() {
        let tmp1 = document.getElementById('BiblioContent')
        if(tmp1.className.includes('HeartAgeCalculatorBibliographyContentHidden')) {
            tmp1.className = tmp1.className.replace(" HeartAgeCalculatorBibliographyContentHidden", "");
            setArrowUp(true)
        } else {
            tmp1.className += " HeartAgeCalculatorBibliographyContentHidden"
            setArrowUp(false)
        }
    }

    function toggleDescription(type,num="") {
        let tmp = document.getElementById("More"+num)
        let tmp2 = document.getElementById("Less"+num)
        if(type === "Less") {
            tmp.className += " HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden"
            tmp2.className = tmp2.className.replace(" HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden", "");
        } else {
            tmp.className = tmp.className.replace(" HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden", "");
            tmp2.className += " HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden"
        }
    }
  
  return (
    <>
    <div className="HeartAgeCalculatorFull" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/BackgroundOneCorner.png')",backgroundPosition: 'left',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
        {Calculated ?
        <div className="HeartAgeCalculatorResultsTitleDiv" style={{backgroundColor: Risk.heartAge > TmpAge ? "#CCCCFF" : "#CCCCFF", marginBlockStart:"145px"}}>
            <div className="HeartAgeCalculatorRightSideTitleFlex">
                <img className="HeartAgeCalculatorRightSideTitleIcon" src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleLogo.png"/>
                <div className="HeartAgeCalculatorRightSideTitleInnerDiv">
                    <div className="HeartAgeCalculatorRightSideUpTitle">Calculadora de Salud</div>
                    <div className="HeartAgeCalculatorRightSideTitle">Resultados</div>
                </div>
            </div>
            <img className="HeartAgeCalculatorResultsTitleYMCLogo" src="https://static.yomecontrolo.cl/public/icons/YMC_Logo_NoText.png"/>
        </div>
        : null}
        <div className="HeartAgeCalculatorInnerDiv" style={Calculated ? {marginBlockStart:"1rem"} : {marginBlockStart:"145px"}}>
            <div className="HeartAgeCalculatorLeftSide">
                {!Calculated ?
                <>
                    <div className="HeartAgeCalculatorLeftSideUpTitle">¿Estoy en riesgo cardiovascular?</div>
                    <h2 className="HeartAgeCalculatorLeftSideTitle">Conoce la edad de tu corazón y el riesgo cardiovascular.</h2>
                    <div className="HeartAgeCalculatorResultsRightSideWhatIfConsequence" id="More">La edad del corazón (o edad vascular) se define como la edad que tendría una persona del mismo sexo que tú con los factores de riesgo cardiovascular tratados y controlados. Eso quiere decir que <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("Less")}>Leer más...</span></div>
                    <div className="HeartAgeCalculatorResultsRightSideWhatIfConsequence HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden" id="Less">La edad del corazón (o edad vascular) se define como la edad que tendría una persona del mismo sexo que tú con los factores de riesgo cardiovascular tratados y controlados. Eso quiere decir que el <span style={{fontWeight: '900'}}>riesgo de sufrir un evento cardiovascular sería equivalente al de una persona sana de esa edad</span>.
                    <br/>El colesterol LDL elevado, la diabetes, el tabaquismo y la hipertensión son los principales factores de riesgo cardiovascular y pueden provocar el desarrollo de enfermedades como el infarto de miocardio, la enfermedad arterial periférica, el ictus isquémico, la estenosis de carótida o un aneurisma de aorta. Su aparición determina el llamado riesgo cardiovascular (CV), que es la probabilidad que tiene una persona de desarrollar una enfermedad cardiovascular.
                    <br/><span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("More")}>Leer menos...</span></div>
                    <img className="HeartAgeCalculatorLeftSideImage" src="https://static.yomecontrolo.cl/public/img/home/HeartAgeRealHeart.png"/>
                    <div className="HeartAgeCalculatorResultsRightSideWhatIfConsequence">Este instrumento está indicado para personas con edades comprendidas <span style={{fontWeight: '900'}}>entre 30 y 65 años</span>, especialmente aquellas que puedan tener uno o varios factores de riesgo cardiovascular y <span style={{fontWeight: '900'}}>no estén diagnosticados previamente de ninguna enfermedad cardiovascular, diabetes tipo II o diabetes tipo I con microalbuminuria.</span></div>
                </>
                :
                <>
                    <div className="HeartAgeCalculatorLeftSideResultContainer">
                        <h5 className="HeartAgeCalculatorLeftSideResultPartName">1. Análisis de tu Riesgo Cardiovascular</h5>
                        <h2 className="HeartAgeCalculatorLeftSideResultTitle">La edad de tu corazón nos dice sobre tu riesgo cardiovascular en los próximos 10 años</h2>
                        <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockSmallScreen" style={{ backgroundColor: Risk.cardioRisk === 0 ? "rgba(139, 197, 63, 1)" : Risk.cardioRisk <= 3 ? "rgba(255, 205, 41, 1)" : "rgba(255, 79, 83, 1)", color: Risk.cardioRisk >= 4 ? "white": ""}}>
                            <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText1">Tienes un corazón de</div>
                            <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText2">{Risk.heartAge} años</div>
                            <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText1">Edad Real: {TmpAge}</div>
                            <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText3">Riesgo {Risk.cardioRiskCat}</div>
                        </div>
                        <div className="HeartAgeCalculatorLeftSideResultHeartBlock" style={{ backgroundColor: Risk.cardioRisk === 0 ? "rgba(139, 197, 63, .5)" : Risk.cardioRisk <= 3 ? "rgba(255, 205, 41, .5)" : "rgba(255, 79, 83, .5)", color: Risk.cardioRisk >= 4 ? "white": ""}}>
                            <img className="HeartAgeCalculatorLeftSideResultHeartImage" src={Risk.cardioRisk === 0 ? "https://static.yomecontrolo.cl/public/img/calculator/BigHeartLowRiskTp.png" : Risk.cardioRisk <= 3 ? "https://static.yomecontrolo.cl/public/img/calculator/BigHeartMidRiskTp.png" : "https://static.yomecontrolo.cl/public/img/calculator/BigHeartHighRiskTp.png"}/>
                            {TmpAge < 40 ?
                            <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlock" style={{ backgroundColor: Risk.cardioRisk === 0 ? "rgba(139, 197, 63, 1)" : Risk.cardioRisk <= 3 ? "rgba(255, 205, 41, 1)" : "rgba(255, 79, 83, 1)", color: Risk.cardioRisk >= 4 ? "white": ""}}>
                                <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText1">Riesgo<br/>Cardiovascular</div>
                                <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText3">{Risk.cardioRiskCat}</div>
                            </div>
                            :
                            <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlock" style={{ backgroundColor: Risk.cardioRisk === 0 ? "rgba(139, 197, 63, 1)" : Risk.cardioRisk <= 3 ? "rgba(255, 205, 41, 1)" : "rgba(255, 79, 83, 1)", color: Risk.cardioRisk >= 4 ? "white": ""}}>
                                <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText1">Tienes un corazón de</div>
                                <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText2">{Risk.heartAge} años</div>
                                <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText1">Edad Real: {TmpAge}</div>
                                <div className="HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText3">Riesgo {Risk.cardioRiskCat}</div>
                            </div>
}
                            <div className="HeartAgeCalculatorLeftSideResultHeartBlockMeterBlock">
                                <div className="HeartAgeCalculatorLeftSideResultHeartBlockMeter" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/RiskMeterHorizontalSimplified.png')",backgroundPosition: 'left',backgroundSize: 'contain',backgroundRepeat: 'no-repeat'}}>
                                    <img className="HeartAgeCalculatorLeftSideResultHeartBlockMeterFace" src={Risk.cardioRisk === 0 ? "https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_LowRiskFace.png" : Risk.cardioRisk <= 3 ? "https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" : "https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_HighRiskFace.png"} style={ Risk.cardioRisk === 0 ? { left: "-5px"} : Risk.cardioRisk <= 3 ? { left: "-3px", right: "0", margin: "0 auto" } : { right: "-5px"} } />
                                </div>
                            </div>
                        </div>
                        {TmpAge < 40 ?
                        <div className="HeartAgeCalculatorLeftSideResultsListed">Tus resultados:<br/>Riesgo: {Risk.cardioRiskCat} {Risk.cardioRisk*20}%</div>
                        :
                        <>
                        <div className="HeartAgeCalculatorLeftSideResultsListed">Tus resultados:<br/>Edad: {TmpAge} / <br className='HeartAgeCalculatorLeftSideResultsLineBreak'/>Edad Corazón: {Risk.heartAge} / <br className='HeartAgeCalculatorLeftSideResultsLineBreak'/> Riesgo: {Risk.cardioRiskCat} {Risk.cardioRisk*20}%</div>
                        <div className="HeartAgeCalculatorLeftSideResultSubtext">Esto nos dice que tu corazón tiene la edad de una persona sana de {Risk.heartAge}, lo que se traduce a las mismas posibilidades de sufrir un evento cardiovascular que una persona de esta edad ({Risk.heartAge}). Si consideras que tu edad real es de {TmpAge}, estas aumentando tu riesgo en {Risk.heartAge-TmpAge} años.</div>
                        </>
}
                    </div>
                    <div className="HeartAgeCalculatorLeftSideResultContainer">
                        <div className="HeartAgeCalculatorLeftSideResultSubtext" id="More1">Este riesgo fue calculado utilizando la metodología SCORE, la cual es una herramienta de evaluación de riesgos fácil de usar, desarrollada para ayudar a los profesionales de la salud a evaluar el riesgo de que su paciente muera por causas cardiovasculares ... <br/><br/><span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("Less",1)} style={{fontWeight: "800"}}>Leer más...</span></div>
                        <div className="HeartAgeCalculatorLeftSideResultSubtext HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden" id="Less1">Este riesgo fue calculado utilizando la metodología SCORE, la cual es una herramienta de evaluación de riesgos fácil de usar, desarrollada para ayudar a los profesionales de la salud a evaluar el riesgo de que su paciente muera por causas cardiovasculares (cardíacas y circulatorias), como infarto de miocardio (ataque cardíaco), insuficiencia cardíaca (incapacidad del corazón para bombear sangre) o accidente cerebrovascular (bloqueo repentino de un vaso sanguíneo que transporta oxígeno y nutrientes) a lo largo de un período de diez años. Incluye múltiples factores de riesgo, la mayoría de los cuales se pueden abordar, como la presión arterial, el colesterol en sangre (nivel de grasas en la sangre) y el hábito de fumar. También incluye factores de riesgo no modificables como la edad y el sexo. SCORE le muestra cómo estos factores de riesgo interactúan para calcular su riesgo general y destaca qué factores de riesgo se pueden tratar para reducirlo.
                        <br/>La información y las recomendaciones que aparecen en esta calculadora son adecuadas en la mayoría de los casos, pero no reemplazan el diagnóstico médico. Para obtener información específica relacionada con su condición personal, consulte a su médico. <br/><br/><span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("More",1)} style={{fontWeight: "800"}}>Leer más...</span></div>
                    </div>
                    <div className="HeartAgeCalculatorLeftSideResultContainer">
                        <h5 className="HeartAgeCalculatorLeftSideResultPartName">2. Indicadores en Riesgo</h5>
                        <h2 className="HeartAgeCalculatorLeftSideResultTitle">¿Qué indicadores están comprometiendo tu salud?</h2>
                        <div className="HeartAgeCalculatorLeftSideResultParameterBox">
                            { RegSistolic > 0 ?
                            <>
                            <div className="HeartAgeCalculatorLeftSideResultParameterBloodBlock" style={{ backgroundColor: TrueSistolic < 120 ? "rgba(139, 197, 63, 1)" : TrueSistolic < 130 ? "rgba(255, 205, 41, 1)" : "rgba(255, 79, 83, 1)", color: TrueSistolic >= 120 & TrueSistolic < 130 ? "#330066" : "white" }}>
                                <img className="HeartAgeCalculatorLeftResultParameterCardiacIcon" src={TrueSistolic >= 120 & TrueSistolic <= 130 ? "https://static.yomecontrolo.cl/public/icons/DarkPurpleCardioHeartTrasparent.png" : "https://static.yomecontrolo.cl/public/icons/WhiteCardioHeartIcon.png"}/>
                                <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerFlex">
                                    <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlock">
                                        <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlockText1">Presión arterial</div>
                                        <h4 className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlockText1">{TrueSistolic}mmHg</h4>
                                    </div>
                                    <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlock">
                                        <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlockText2">Rango recomendado</div>
                                        <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlockText2">100 a 120 mmHg</div>
                                    </div>
                                </div>
                            </div>
                            {Risk.heartAge - RegSistolic > 0 & TmpAge >= 40 ?
                            <h4 className="HeartAgeCalculatorLeftSideResultParameterTitle">Si regulas tu presión arterial al 120mmHg ganarías {Risk.heartAge - RegSistolic} años</h4>
                            : null}
                            {TrueSistolic > 130 ?
                            <>
                            <div className="HeartAgeCalculatorLeftSideResultParameterSubtitle" id="More2">Una presión arterial de {TrueSistolic} mmHg es extremadamente peligrosa y se considera una crisis hipertensiva. <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("Less",2)} style={{fontWeight: "800"}}>Leer más...</span></div>
                            <div className="HeartAgeCalculatorLeftSideResultParameterSubtitle HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden" id="Less2">Una presión arterial de {Sistolic} mmHg es extremadamente peligrosa y se considera una crisis hipertensiva. Esta es una emergencia médica que requiere atención inmediata porque puede causar daños graves a órganos vitales y provocar complicaciones potencialmente mortales. <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("More",2)} style={{fontWeight: "800"}}>Leer menos...</span></div>
                            </>
                            : TrueSistolic > 120 ?  
                            <div className="HeartAgeCalculatorLeftSideResultParameterSubtitle">Una presión arterial de entre 120 y 130 mmHg se considera alta, pero no peligrosa. Cuida tu presión para mentenerte saludable.</div>
                            :
                            <div className="HeartAgeCalculatorLeftSideResultParameterSubtitle">Tu presión arterial está en un rango saludable.</div>
                            }
                            <div className="HeartAgeCalculatorLeftSideResultParameterHorizontalLine"></div>
                            </>
                            : null}
                            {TrueIMC.length !== 0 ?
                            <>
                            <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlock">
                                <img className="HeartAgeCalculatorLeftResultParameterCardiacIcon" src="https://static.yomecontrolo.cl/public/icons/YellowWeightIcon.png"/>
                                <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerFlex">
                                    <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlock">
                                        <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlockText1">Peso: {TrueIMC[1]}</div>
                                        <h4 className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlockText1">{TrueIMC[4]}Kg</h4>
                                    </div>
                                    <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlock">
                                        <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlockText2">Rango recomendado</div>
                                        <div className="HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlockText2">{TrueIMC[2]} a {TrueIMC[3]} kg</div>
                                    </div>
                                </div>
                            </div>
                            </>
                            : null}
                            { RegCholes > 0 & Risk.heartAge - RegCholes > 1 ?
                            <>
                            <div className="HeartAgeCalculatorLeftSideResultParameterHorizontalLine"></div>
                            {TmpAge >= 40 ?
                            <h4 className="HeartAgeCalculatorLeftSideResultParameterTitle">Si regulas tu colesterol total a 154mg/dL ganarías {Risk.heartAge - RegCholes} años</h4>
                            : null}
                            <div className="HeartAgeCalculatorLeftSideResultParameterSubtitle" id="More3">Para mejorar tu peso de manera saludable, realiza ajustes en tu alimentación, controlando porciones y evitando alimentos ultraprocesados. <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("Less",3)} style={{fontWeight: "800"}}>Leer más...</span></div>
                            <div className="HeartAgeCalculatorLeftSideResultParameterSubtitle HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden" id="Less3">Para mejorar tu peso de manera saludable, realiza ajustes en tu alimentación, controlando porciones y evitando alimentos ultraprocesados. Incorpora más frutas, verduras y proteínas saludables. Aumenta tu actividad física con ejercicios aeróbicos y de fuerza. Establece metas realistas, maneja el estrés y asegúrate de dormir lo suficiente. <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("More",3)} style={{fontWeight: "800"}}>Leer menos...</span></div>
                            </>
                            : null}
                        </div>
                        <h4 className="HeartAgeCalculatorLeftSideResultParameterLowerText">Si mejoras estos indicadores en riesgo podrás mejorar significativamente tu proyección de salud futura.</h4>
                    </div>
                    <div className="HeartAgeCalculatorLeftSideResultContainer">
                        <h5 className="HeartAgeCalculatorLeftSideResultPartName">3. Aprende a mejorar tus resultados</h5>
                        <h2 className="HeartAgeCalculatorLeftSideResultTitle" style={{marginBlockEnd: "0px"}}>Cambia tus hábitos de vida y alcanza una vida saludable que te permita <span className='HeartAgeCalculatorLeftSideResultTitleBlueRadial'>ganar años de buen vivir</span></h2>
                        <img className="HeartAgeCalculatorLeftSideResultHealthFactorsColumns" src="https://static.yomecontrolo.cl/public/img/calculator/HealthFactorColumns.png"/>
                        <div className="HeartAgeCalculatorLeftSideResultHealthFactorsSubtext">La combinación de factores de cuidado de la salud puede permitir tanto a hombres cómo mujeres ganar una cantidad significativa de años  de vida. Si quieres mejorar tu salud ahora puedes conocer nuestra ruta del bienestar & autocuidado.</div>
                    </div>
                    <div className="HeartAgeCalculatorLeftSideResultContainer">
                        <h5 className="HeartAgeCalculatorLeftSideResultPartName">4. ¿Cómo ganar años de vida?</h5>
                        <h2 className="HeartAgeCalculatorLeftSideResultTitle">La suma de estos factores te permitirá alcanzar entre 12 y 14 años para hombre y mujeres</h2>
                        <div className="HeartAgeCalculatorLeftSideResultHealthFactorsFlex">
                            <img className="HeartAgeCalculatorLeftSideResultHealthFactorsImage" src="https://static.yomecontrolo.cl/public/img/calculator/Healthfactor_Wine.png"/>
                            <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSide">
                                <h3 className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideUpNumber">+5</h3>
                                <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideTitle">Menos alcohol por más años de vida</div>
                                <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideSubtitle">Si disminuyes el consumo de bebidas alcohólicas puedes alcanzar hasta 5 años más de vida.</div>
                            </div>
                        </div>
                        <div className="HeartAgeCalculatorLeftSideResultHealthFactorsFlex">
                            <img className="HeartAgeCalculatorLeftSideResultHealthFactorsImage" src="https://static.yomecontrolo.cl/public/img/calculator/Healthfactor_Lungs.png"/>
                            <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSide">
                                <h3 className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideUpNumber">+4</h3>
                                <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideTitle">Fumar nunca sumará a tu salud</div>
                                <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideSubtitle">Si dejas de fumar no solo mejoras tu salud diaria, también puedes ganar hasta 10 años de vida.</div>
                            </div>
                        </div>
                        <div className="HeartAgeCalculatorLeftSideResultHealthFactorsFlex">
                            <img className="HeartAgeCalculatorLeftSideResultHealthFactorsImage" src="https://static.yomecontrolo.cl/public/img/calculator/Healthfactor_Road.png"/>
                            <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSide">
                                <h3 className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideUpNumber">+4</h3>
                                <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideTitle">Cuida tu Índice de Masa Corporal (IMC)</div>
                                <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideSubtitle">Si cuidas tu índice de masa corporal puedes ganar 4 años aproximados de vida.</div>
                            </div>
                        </div>
                        <div className="HeartAgeCalculatorLeftSideResultHealthFactorsFlex">
                            <img className="HeartAgeCalculatorLeftSideResultHealthFactorsImage" src="https://static.yomecontrolo.cl/public/img/calculator/Healthfactor_Food.png"/>
                            <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSide">
                                <h3 className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideUpNumber">+7.2</h3>
                                <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideTitle">Para cuidar el colesterol</div>
                                <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideSubtitle">Limita los alimentos grasos y salados, come sin culpa de frutas de verduras y legumbres. Evita dulces, precocinados y alimentos ultra procesados.
                                    <br/>Mantenerte en un peso saludable te puede sumar hasta 7.2 años de vida.</div>
                            </div>
                        </div>
                        <div className="HeartAgeCalculatorLeftSideResultHealthFactorsFlex">
                            <img className="HeartAgeCalculatorLeftSideResultHealthFactorsImage" src="https://static.yomecontrolo.cl/public/img/calculator/HealthFactor_Wave.png"/>
                            <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSide">
                                <h3 className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideUpNumber">+3.4</h3>
                                <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideTitle">El movimiento es vida</div>
                                <div className="HeartAgeCalculatorLeftSideResultHealthFactorsRightSideSubtitle">Te recomendamos agregar dentro de tu semana 150-300 min de actividades de intensidad moderada ó 75 a 150 min de ejercicios de intensidad alta. Lo importantes es que mantengas tu cuerpo en movimiento adaptado a tu condición física.
                                    <br/>Puedes ganar hasta 3.4 años de vida.</div>
                            </div>
                        </div>
                        <div className="HeartAgeCalculatorLeftSideResultHealthFactorsLastText">La importancia de un estilo de vida saludable</div>
                    </div>
                </>
}
            </div>
            <div className="HeartAgeCalculatorRightSide">
                <div className="HeartAgeCalculatorLeftSideResultContainer">
                <div className="HeartAgeCalculatorRightSideTitleDiv">
                    <div className="HeartAgeCalculatorRightSideTitleFlex">
                        <img className="HeartAgeCalculatorRightSideTitleIcon" src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleLogo.png"/>
                        <div className="HeartAgeCalculatorRightSideTitleInnerDiv">
                            <div className="HeartAgeCalculatorRightSideUpTitle">Calculadora de Salud</div>
                            <h3 className="HeartAgeCalculatorRightSideTitle">Edad del corazón</h3>
                        </div>
                    </div>
                    <img className="HeartAgeCalculatorResultsTitleYMCLogo" src="https://static.yomecontrolo.cl/public/icons/YMC_Logo_NoText.png"/>
                </div>
                <div className="HeartAgeCalculatorRightSideSurveyTitle">Completa el cuestionario para conocer la edad de tu corazón y tu riesgo cardiovascular.</div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1">1. Indícanos tu sexo</div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1TileFlex">
                    <div className={Gender === 1 ? "HeartAgeCalculatorRightSideSurveyQ1Tile HeartAgeCalculatorRightSideSurveyQ1ActiveTile" : "HeartAgeCalculatorRightSideSurveyQ1Tile"} id="MaleTile" onClick={() => GenderChange("M")}>
                        Hombre <br/>
                        <img className={Gender === 1 ? "HeartAgeCalculatorRightSideSurveyQ1TileIcon HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon" : "HeartAgeCalculatorRightSideSurveyQ1TileIcon"} src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleMaleIcon.png" id="MaleTileIcon"/>
                    </div>
                    <div className={Gender === 2 ? "HeartAgeCalculatorRightSideSurveyQ1Tile HeartAgeCalculatorRightSideSurveyQ1ActiveTile" : "HeartAgeCalculatorRightSideSurveyQ1Tile"} id="FemaleTile" onClick={() => GenderChange("F")}>
                        Mujer <br/>
                        <img className={Gender === 2 ? "HeartAgeCalculatorRightSideSurveyQ1TileIcon HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon" : "HeartAgeCalculatorRightSideSurveyQ1TileIcon"} src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleFemaleIconT.png" id="FemaleTileIcon"/>
                    </div>
                </div>
                {validation["Gender"] === "invalid" ?
                    <div className='BloodAlert' style={{ color: "red", fontWeight: "500" }}> Escoja su sexo </div>
                : null }
                <div className="HeartAgeCalculatorRightSideSurveyQuestionsFlex">
                    <div className="HeartAgeCalculatorRightSideSurveyQuestion">
                        <div className="HeartAgeCalculatorRightSideSurveyQ1">2. Indícanos tu edad</div>
                        <select defaultValue={Age} id="YMCAge" className='DoBTile HeartAgeCalculatorRightSideSurveyQ1AgeInput rounded-2' onChange={(e) => setAge(parseInt(e.target.value))}>
                        {Array.from({ length: 36 }, (_, i) => i+30).map((day) => (
                            <option key={day} value={parseInt(day)}>{day}</option>
                        ))}
                        </select>
                    </div>
                    <div className="HeartAgeCalculatorRightSideSurveyQuestion">
                        <div className="HeartAgeCalculatorRightSideSurveyQ1">3. ¿Fumas?</div>
                        <div className="HeartAgeCalculatorRightSideSurveyQ1TileFlex">
                            <div className="HeartAgeCalculatorRightSideSurveyQ1Tile" id="NoSmokeTile" onClick={() => SmokeChange("NO")}>
                                No
                            </div>
                            <div className="HeartAgeCalculatorRightSideSurveyQ1Tile" id="SmokeTile" onClick={() => SmokeChange("YES")}>
                                Sí
                            </div>
                        </div>
                        {validation["Smoke"] === "invalid" ?
                            <div className='BloodAlert' style={{ color: "red", fontWeight: "500" }}> Indique si fuma o no </div>
                        : null }
                    </div>
                </div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1Flex">
                    <div className="HeartAgeCalculatorRightSideSurveyQ1">4. Indícanos tu presión arterial sistólica</div>
                    <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("PressureInfo")}>?</div>
                </div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1SuggestionText InformationCollapsed" id="PressureInfo">Es la cifra superior (y más alta) en una medición de la presión arterial.</div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1MeasureFlex">
                    <input
                        value={Sistolic}
                        className="HeartAgeCalculatorRightSideSurveyQ4InputTile rounded-1"
                        style={{ border: validation["Blood"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
                        id="YMCSistolic"
                        onChange={(e) => handleChange(e.target.value,setSistolic)}
                        placeholder="Ingresa tu presión sistólica" />
                    <div className="HeartAgeCalculatorRightSideSurveyQ4InputMeasure">mmHg</div>
                </div>
                {validation["Blood"] === "invalid" ?
                    <div className='BloodAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                : null }
                <div className="HeartAgeCalculatorRightSideSurveyQ1Flex">
                    <div className="HeartAgeCalculatorRightSideSurveyQ1">5. Indícanos tu colesterol total (venoso) </div>
                    <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("CholesterolSuggestion")}>?</div>
                </div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1SuggestionText InformationCollapsed" id="CholesterolSuggestion">¿No sabes tu Colesterol Total? Te damos una <span className='HeartAgeCalculatorRightSideSurveyQ1SuggestionLink' onClick={() => {setToLipidic(true); localStorage.setItem("VisualizePack",JSON.stringify("PM00YMC041"));}}>orden médica gratuita</span> con los exámenes necesarios, para que te pongas al día con tu corazón.</div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1MeasureFlex">
                    <input
                        value={Choles}
                        className="HeartAgeCalculatorRightSideSurveyQ4InputTile rounded-1"
                        style={{ border: validation["Choles"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
                        id="YMCCholes"
                        onChange={(e) => handleChange(e.target.value,setCholes)}
                        placeholder="Ingresa tu colesterol total" />
                    <div className="HeartAgeCalculatorRightSideSurveyQ4InputMeasure">mg/dL</div>
                </div>
                {validation["Choles"] === "invalid" ?
                    <div className='CholesAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                : null }
                <div className="HeartAgeCalculatorRightSideSurveyQuestionsFlex">
                    <div className="HeartAgeCalculatorRightSideSurveyQuestion">
                        <div className="HeartAgeCalculatorRightSideSurveyQ1">6. Ingresa tu peso </div>
                        <div className="HeartAgeCalculatorRightSideSurveyQ1MeasureFlex">
                            <input
                                value={Weight}
                                className="HeartAgeCalculatorRightSideSurveyQ4InputTile rounded-1"
                                style={{ border: validation["Weight"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
                                id="YMCWeight"
                                onChange={(e) => handleChange(e.target.value,setWeight)}
                                placeholder="Ingresa tu peso" />
                            <div className="HeartAgeCalculatorRightSideSurveyQ4InputMeasure">Kg</div>
                        </div>
                        {validation["Weight"] === "invalid" ?
                            <div className='WeightAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                        : null }
                    </div>
                    <div className="HeartAgeCalculatorRightSideSurveyQuestion">
                        <div className="HeartAgeCalculatorRightSideSurveyQ1">7. Ingresa tu altura </div>
                        <div className="HeartAgeCalculatorRightSideSurveyQ1MeasureFlex">
                            <input
                                value={Height}
                                className="HeartAgeCalculatorRightSideSurveyQ4InputTile rounded-1"
                                style={{ border: validation["Height"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
                                id="YMCHeight"
                                onChange={(e) => handleChange(e.target.value,setHeight)}
                                placeholder="Ingresa tu altura" />
                            <div className="HeartAgeCalculatorRightSideSurveyQ4InputMeasure">cm</div>
                        </div>
                        {validation["Height"] === "invalid" ?
                            <div className='HeightAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                        : null }
                    </div>
                </div>
                <div className='HeartAgeCalculatorRightSideSurveyQuestionsFlex'>
                    <div className="HeartAgeCalculatorRightSideSurveyQ1">8. ¿Cuando fue la última vez que te hiciste un chequeo preventivo?</div>
                    <div className="HeartAgeCalculatorRightSideSurveyTimeInput">
                        <select defaultValue="<1Año" className='DoBTile HeartAgeCalculatorRightSideSurveyQ1YearsInput rounded-2' style={{ border: "#cff9ff solid 1px" }} onChange={(e) => setRecent(e.target.value)}>
                            <option key={"Never"} value={"Never"}>Nunca</option>
                            <option key={">1Año"} value={">1Año"}>&gt; 1 Año</option>
                            <option key={"<1Año"} value={"<1Año"}>&lt; 1 Año</option>
                        </select>
                    </div>
                </div>

                {HeartAgeCalculated ?
                    <div className="HeartAgeCalculatorRightSideSurveyCalculate" onClick={() => HandleSubmit()}>
                        <div className="HeartAgeCalculatorResultsRightSideEmailButtonText">Recalcular</div>
                        <div className="HeartAgeCalculatorResultsRightSideCalculatorIconDiv">
                            <img className="HeartAgeCalculatorResultsRightSideCalculatorIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                        </div>
                    </div>
                :
                TaskStart ?
                    <div className="HeartAgeCalculatorRightSideSurveyCalculate">
                        <div className="HeartAgeCalculatorResultsRightSideEmailButtonText">Calculando...</div>
                        <div className="HeartAgeCalculatorResultsRightSideCalculatorIconDiv">
                            <img className="HeartAgeCalculatorResultsRightSideCalculatorIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                        </div>
                    </div>
                :
                    <div className="HeartAgeCalculatorRightSideSurveyCalculate" onClick={() => HandleSubmit()}>
                        <div className="HeartAgeCalculatorResultsRightSideEmailButtonText">Calcular</div>
                        <div className="HeartAgeCalculatorResultsRightSideCalculatorIconDiv">
                            <img className="HeartAgeCalculatorResultsRightSideCalculatorIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                        </div>
                    </div>
                }
                </div>
                {Calculated ?
                <div className="HeartAgeCalculatorResultsRightSidePackBlock">
                    <img className="HeartAgeCalculatorResultsRightSidePackBlockExamIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleExamIcon.png"/>
                    <div className="HeartAgeCalculatorResultsRightSidePackBlockUpTitle">Campaña mes del corazón 2024</div>
                    <div className="HeartAgeCalculatorResultsRightSidePackBlockTitle">¡Hazlo por ti! Para que vivas más y mejor.</div>
                    <div className="HeartAgeCalculatorResultsRightSidePackBlockSubtitle">Durante el mes del corazón,  descarga tu orden médica gratuita con los exámenes necesarios para que te realices un “Chequeo Cardiovascular Preventivo” y te pongas al día con tu corazón.</div>
                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPack">
                        <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/packs/PM00YMC041.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}><div className="ExamChoiceByGenderPackBoxImageGradient"></div></div>
                        <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackTitle">Chequeo cardiovascular preventivo</div>
                        <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFlex">
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackSubtitle">Exámenes:</div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackSubtitle">({packslist["PM00YMC041"].exams.length + packslist["PM00YMC041"].examsPro.length})</div>
                        </div>
                        <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFlex">
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIconsFlex">
                                <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleMicroscopeIconTr.png"/>
                                <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleCardioHeartTrasparent.png"/>
                                <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleDNAIconTr.png" style={{ paddingInline:"1px" }}/>
                            </div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFree">Gratis</div>
                        </div>
                        <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonFlex" onClick={() => {setToLipidic(true); localStorage.setItem("VisualizePack",JSON.stringify("PM00YMC041"));}}>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonText">Ver Detalle</div>
                            <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleExamIcon.png"/>
                        </div>
                    </div>
                </div>
                : null}
            </div>
        </div>

        {Calculated ?
        <div className="HeartAgeCalculatorInnerDiv">
            <div className="HeartAgeCalculatorLeftSideResultContainer">
                <div className="HeartAgeCalculatorLeftSideResultInnerContainer">
                    <h5 className="HeartAgeCalculatorLeftSideResultPartName">Antecedentes del cálculo</h5>
                    <h2 className="HeartAgeCalculatorLeftSideResultTitle">Condiciones de Uso de la Calculadora de Salud</h2>
                </div>
                {LongText ?
                <>
                    <div className="HeartAgeCalculatorLeftSideResultContainer2">
                        <div className="HeartAgeCalculatorLeftSideResultTextColumn">La edad del corazón, de nuestro sistema vascular, es un gran indicador de nuestra esperanza de vida. O como mínimo de la probabilidad que tenemos de fallecer de un infarto o de un ictus en los próximos diez años. Así que convendría conocerla. Y los años del corazón no siempre coinciden con los del calendario, con la edad real de cada persona. Factores como el tabaquismo, la diabetes, el colesterol elevado o la hipertensión aceleran el envejecimiento del corazón y las arterias, de modo que una persona hipertensa de 40 años que fume y tenga niveles de colesterol LDL elevador puede tener el corazón de alguien de 63.
                            <br/>Por ello, y para concienciar sobre el riesgo cardiovascular, el doctor Jose Ignacio Cuende – especialista en medicina interna del hospital universitario Rio Carrion de Palencia y profesor de la Universidad de Valladolid ha desarrollado la Calculadora de la Edad del Corazón, una herramienta basada en un algoritmo que ha presentado la farmacéutica Sanofi y que puedes consultarlo de manera online en nuestra calculadora de salud/ edad del corazón.</div>
                        <div className="HeartAgeCalculatorLeftSideResultTextColumn">Con el objetivo de concienciar a la población sobre las consecuencias de tener un riesgo cardiovascular elevado, Yomecontrolo trae la Calculadora de la edad del corazón a Chile. La calculadora estima la edad vascular de una persona cruzando datos de sexo y edad, si es fumador o no, y a las cifras de su presión arterial y el nivel de colesterol. Con ello cubre todos los valores de riesgo absoluto que recogen las tablas SCORE*, que son las que estiman la probabilidad de muerte vascular en personas de hasta 65 años de distintas regiones europeas, incluyendo un factor corrector en caso de población diabética. [lV1]
                            <br/>Para desarrollarla, Cuende se ha basado en los datos que él mismo publicó en 2010 en el European Heart Journal sobre los métodos que permiten calcular la edad vascular. “No se trata de un avance tecnológico especial, porque se basa en una ecuación matemática que estaba desarrollada hace tiempo para conocer el riesgo que tiene una persona de sufrir un evento cardiovascular como un infarto o un ictus a diez años vista, pero la calculadora transforma esos números en algo mucho más grafico como los años de tu corazón y los años de envejecimiento prematuro que carga encima, o cómo poder rejuvenecerlo si mejoras tus hábitos y controlas tus factores de riesgo”, explica Ignacio Ferreira, jefe de Cardiología del Hospital Universitario Vall D'Hebron de Barcelona.
                            <br/>1 Cuende Jl, Cuende N, Calaveras-Lagartos J. How to calculate vascularage whit the SCORE Project scales: a new method of cardiovascular rik evaluation. Eur Heart J. 2010:31:2351-8
                            <br/>[lV1]Si esto es para la población europea, que sucede con el calculo en la población chilena, recuerdo que Jorge comento que estaba validado el traslado del calculo, pero como varian las distintas regiones en chile o países en latinoamerica
                        </div>
                    </div>
                    <div className="HeartAgeCalculatorLeftSideResultReadOption" onClick={() => setLongText(false)}>Mostrar menos...</div>
                </>
                : 
                <>
                    <div className="HeartAgeCalculatorLeftSideResultContainer2">
                        <div className="HeartAgeCalculatorLeftSideResultTextColumnFull">La edad del corazón, de nuestro sistema vascular, es un gran indicador de nuestra esperanza de vida. O como mínimo de la probabilidad que tenemos de fallecer de un infarto o de un ictus en los próximos diez años. Así que convendría conocerla. Y los años del corazón no siempre coinciden con los del calendario, con la edad real de cada persona. ...</div>
                    </div> 
                    <div className="HeartAgeCalculatorLeftSideResultReadOption" onClick={() => setLongText(true)}>Mostrar más...</div>
                </>
                }
            
            </div>
        </div>
        : null}

        <div className="HeartAgeCalculatorInnerDiv" style={{display: 'block'}}>
            <div className="HeartAgeCalculatorBibliographyFlex" onClick={() => displayBiblio()}>
                <div className="HeartAgeCalculatorBibliography">Referencias Bibliográficas&ensp;</div>
                {ArrowUp ?
                <FontAwesomeIcon 
                    icon={faAngleDown} 
                    className='HeartAgeCalculatorBibliographyArrow fa-rotate-180'
                    />
                :
                <FontAwesomeIcon 
                    icon={faAngleDown} 
                    className='HeartAgeCalculatorBibliographyArrow'
                    />
                }
            </div>
            <div id='BiblioContent' className="HeartAgeCalculatorBibliographyContent HeartAgeCalculatorBibliographyContentHidden">
                1.- Cuende JI, Cuende N, Calaveras-Lagartos J. How to calculate vascular age with the SCORE project scales: a new method of cardiovascular risk evaluation. Eur Heart J. 2010 Oct;31(19):2351-8. doi: 10.1093/eurheartj/ehq205. Epub 2010 Jun 28. PMID: 20584778.
                <br/>
                2.- María Teresa Lira, Estratificación de riesgo cardiovascular: conceptos, análisis crítico, desafíos e historia de su desarrollo en Chile, Revista Médica Clínica Las Condes. <a href='https://www.sciencedirect.com/science/article/pii/S0716864022001055' target="_blank">Link externo</a>
            </div>
        </div>
    </div>
    <Newsletter/>
    </>
  );
}

export default HeartAgeCalculator;