import React, { useContext, useState, useEffect } from 'react';
import "../../Styles/Landing.css";
import { Packs } from '../Context/PacksContext';
//import PackRectangle from "../../Photos/PackRectangle.png"
import YMCInstructions1 from "../../Photos/LandingInstruction1.png"
import YMCInstructions3 from "../../Photos/LandingInstruction3.png"
//import YMCBlockImage6 from "../../Photos/YMCBlockImage6.png"
//import YMCSwirlyLines1 from "../../Photos/YMCSwirlyLines1.png"
//import YMCSwirlyLines2 from "../../Photos/YMCSwirlyLines2.png"
//import YMCSwirlyLines3 from "../../Photos/YMCSwirlyLines3.png"
//import YMCSwirlyLines4 from "../../Photos/YMCSwirlyLines4.png"
//import YMCSelfCareImage from "../../Photos/LandingSelfCareBlock.png"
import MakeItEasy from "./MakeItEasy"
import DidYouKnow from "./DidYouKnow"
import YouCanTrust from "./YouCanTrust"
import Newsletter from "../ContactRoute/Newsletter"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpLong } from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
//import { faHeart } from "@fortawesome/free-regular-svg-icons";
//import packslist from "../../../JSONS/updatedPacks.json"
import LazyLoad from 'react-lazyload';


function LandingSlider() {
  const Navigate = useNavigate();

  const re = new RegExp(/^.*\//);
  const root = re.exec(window.location.href)

  window.onscroll = function() {
    if(document.URL === root[0]) {
      let tmp = document.getElementById("ToTheTop")
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        tmp.className = tmp.className.replace(" ScrollBackToTopHidden", "");
      } else {
        if(!tmp.className.includes("ScrollBackToTopHidden")) tmp.className += " ScrollBackToTopHidden"
      }
    }
  }

  //const [Gender, setGender] = useState('Mixed');
  //const [PacksVisible, setPacksVisible] = useState(["CM00YMC001","PM00YMC002","PM40YMC003"]);
  const [GenderPack, setGenderPack] = useState(false);
  const [ToLipidic, setToLipidic] = useState(false)

  //const {
  //  SetVisualizePack, SetChosenPackGender
  //} = useContext(Packs);
  const {
    SetChosenPackGender
  } = useContext(Packs);

  useEffect(() => {
    if(GenderPack) {
      Navigate("/packs-de-examenes")
    }
  });

  useEffect(() => {
    if(ToLipidic) {
      Navigate("/detalles-de-pack?pack=PM00YMC041")
    }
  });

  //const description1 = "Si, es medicina centrada en el futuro del paciente, creamos perfiles de riesgo para abordar de manera prematura la aparición de enfermedades basado en datos de la población / paciente."
  //const description2 = "Te entregamos una cadena completa de exámenes, atenciones y derivaciones para gestionar el cuidado y extender la esperanza de vida de manera trazable."
  //const description3 = "Reducimos etapas en el proceso de cuidado medico, dando una estrategia de planificación para el tratamiento o cuidado en el largo plazo."

  //function toggleDescription(id,type) {
  //  let tmp = document.getElementById(id.concat("More"))
  //  let tmp2 = document.getElementById(id.concat("Less"))
  //  if(type === "More") {
  //      tmp.className += " hidden"
  //      tmp2.className = tmp2.className.replace(" hidden", "");
  //  } else {
  //      tmp.className = tmp.className.replace(" hidden", "");
  //      tmp2.className += " hidden"
  //  }
  //}

  return (
    <div className="LandingSliderDiv">
    <div className="LandingSliderInnerDiv">
    <div className="LandingSliderImageContain">
    <Carousel className='LandingSliderImageCarousel' swipeScrollTolerance={100} preventMovementUntilSwipeScrollTolerance={true} showArrows={false} showStatus={false} showIndicators={true} dynamicHeight={false} showThumbs={false} autoPlay={true} infiniteLoop={true} interval={7000}
    renderIndicator={(onClickHandler, isSelected, index, label) => {
      return (
            <div 
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
            className={isSelected ? "LandingSliderUpperIndicator LandingSliderUpperIndicatorActive rounded-pill" : "LandingSliderUpperIndicator rounded-pill"}
            ></div>
      );
    }}>
        <div className="LandingSliderImage rounded-4" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/BackgroundOneCornerHueYMC.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
          <div className="LandingSlider2Flex">
            <div className='LandingSliderTexts'>
              <div className="LandingSlider2UpTitle">
                Chequea tu salud
              </div>
              <h1 className="LandingSlider2Title">
              ¡Pide tu orden médica y gana! 
              </h1>
              <div className="LandingSlider2Subtitle" style={{ fontSize:"1.3em"}}>
              Utiliza el cupón <b>"YMC2024"</b> para llevar tu orden médica GRATIS y estarás participando por un reloj Garmin VIVOSMART 5.
              </div>
              <div className='LandingSlider2RightSideButtonFlex' onClick={() => Navigate("/orden-medica")}>
                <div className='LandingSlider2RightSideButtonLeft'>
                   Orden Médica
                </div>
                <div className="LandingSlider2RightSideButtonRight" style={{ display:"flex"}}>
                  <img style={{ marginRight:"10px" }} src="https://static.yomecontrolo.cl/public/icons/WhiteExamIcon.png" className="LandingSlider2RightSideButtonRightImage" />
                  <img src="https://static.yomecontrolo.cl/public/icons/WhiteCartIcon.png" className="LandingSlider2RightSideButtonRightImage" alt="cart"/>
                </div>
              </div>
            </div>
            <div className='LandingSlider2RightSide'> 
              <LazyLoad once >
              <img alt="" className="LandingSlider2RightSideImage" style={{ borderRadius:"41px" }} src="https://static.yomecontrolo.cl/public/img/home/sorteo-112024.jpeg"/>
              </LazyLoad>
            </div>
          </div>
        </div>
       <div className="LandingSliderImage rounded-4" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/BackgroundOneCornerHueYMC.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
          <div className="LandingSlider2Flex">
            <div className='LandingSliderTexts'>
              <div className="LandingSlider2UpTitle">
                Noviembre - Mes de la diabetes
              </div>
              <h1 className="LandingSlider2Title">
                Conoce tu riesgo de padecer diabetes
              </h1>
              <div className="LandingSlider2Subtitle">
                Para que puedas conocerla hemos habilitado una calculadora para averiguarlo en cuestión de minutos. Funciona mediante un reconocido test llamado FINDRISC, tras responder pocas preguntas podrán conocer tu riesgo a 10 años.
              </div>
              <div className='LandingSlider2RightSideButtonFlex' onClick={() => Navigate("/riesgo-diabetes")}>
                <div className='LandingSlider2RightSideButtonLeft'>
                  Descúbrelo
                </div>
                <div className="LandingSlider2RightSideButtonRight">
                  <img alt="" className="LandingSlider2RightSideButtonRightImage" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                </div>
              </div>
            </div>
            <div className='LandingSlider2RightSide'> 
              <LazyLoad once >
              <img alt="" className="LandingSlider2RightSideImage" src="https://static.yomecontrolo.cl/public/img/calculator/diabetes/DiabetesCalculator.png"/>
              </LazyLoad>
            </div>
          </div>
        </div>
        <div className="LandingSliderImage rounded-4" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/top-banner.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>

          <div className='LandingSliderTexts LandingSliderTextsWider'>
            <h1 className="LandingSliderTitle">
            ¿Te quieres hacer un chequeo de salud, pero no sabes por dónde empezar?
            </h1>
            <div className="LandingSliderText">
            Utiliza nuestro recomendador inteligente para crear una orden médica personalizada con los exámenes preventivos que te corresponde realizar según tu perfil de riesgo.
            <br/>O accede directamente a una orden médica con los packs de exámenes que se ajusten a tus necesidades.<br/>Cada pack desde $2.990 pesos.
            </div>
            <div className='LandingSliderButtonFlex'> 
              <div className='HeartLandingButton' onClick={() => Navigate("/recomendador/paso-1")}>
                <div className='HeartLandingButtonContain'>
                Orden Médica Personalizada
                </div>
              </div>
              <div className='HeartLandingButtonRight' onClick={() => {SetChosenPackGender("Mixed"); setGenderPack(true);}}>
                <div className='HeartLandingButtonContain'>
                Packs de Exámenes 
                </div>
              </div>
            </div>
          </div>
        </div>
         {/*
        <div className="LandingSliderImage rounded-4" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/BackgroundOneCornerHueYMC.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
          <div className="LandingSlider2Flex">
            <div className='LandingSliderTexts'>
              <div className="LandingSlider2UpTitle">
                Cuida tu corazón
              </div>
              <h1 className="LandingSlider2Title">
              Para vivir más y mejor, Chequea tu corazón 
              </h1>
              <div className="LandingSlider2Subtitle">
              Las enfermedades cardiovasculares son una las principales  causas de muerte en Chile, representando más de una cuarta parte de todos los fallecimientos registrados cada año.
              <br/>
              Durante todo agosto, te daremos acceso gratuito a la orden médica con los exámenes de laboratorio y procedimientos médicos necesarios para que te realices un  chequeo cardiovascular preventivo básico. Hazlo por ti y tu familia. 
              </div>
              <div className='LandingSlider2RightSideButtonFlex' onClick={() => {localStorage.setItem("VisualizePack",JSON.stringify("PM00YMC041")); document.body.scrollTop = 0; document.documentElement.scrollTop = 0; setToLipidic(true);}}>
                <div className='LandingSlider2RightSideButtonLeft'>
                  Emitir Orden Médica
                </div>
                <div className="LandingSlider2RightSideButtonRight">
                  <img alt="" className="LandingSlider2RightSideButtonRightImage" src="https://static.yomecontrolo.cl/public/icons/WhiteExamIcon.png"/>
                </div>
              </div>
            </div>
            <div className='LandingSlider2RightSide'> 
              <LazyLoad once >
              <img alt="" className="LandingSlider2RightSideImage" style={{ paddingInline:"10%" }} src="https://static.yomecontrolo.cl/public/img/home/LandingSliderFreePackImage.png"/>
              </LazyLoad>
            </div>
          </div>
        </div>
        <div className="LandingSlider2 rounded-4">
          <div className="LandingSlider2Flex">
            <div className='LandingSlider2LeftSide'>
              <div className="LandingSlider2UpTitle">
                Reduce el riesgo de accidentes CV
              </div>
              <div className="LandingSlider2Title">
                Agosto es el mes del cuidado del corazón
              </div>
            </div>
            <div className='LandingSlider2RightSide'> 
              <div className='LandingSlider2RightSideButtonAligner'></div>
              <div className='LandingSlider2RightSideButtonFlex'>
                <div className='LandingSlider2RightSideButtonLeft'>
                  Aprende a prevenirlo
                </div>
                <div className="LandingSlider2RightSideButtonRight">
                  <img alt="" className="LandingSlider2RightSideButtonRightImage" src="https://static.yomecontrolo.cl/public/icons/PurpleCardioHeartTrasparent.png"/>
                </div>
              </div>
            </div>
          </div>
          <div className="LandingSlider2BottomImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/LandingSlider2Bottom.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
        </div>*/}
      </Carousel>
      </div>
      {/* <div className='rounded-circle m-1 dotActive'></div>
      <div className='rounded-circle m-1 dotInctive'></div>
      <div className='rounded-circle m-1 dotInctive2'></div> */}
      <div className='LandingMedicOrderSection'>
        <div className='plus'></div>
        <div className='LandingMedicOrderSectionTitle'>Encuentra los exámenes que necesitas</div>
        <div className='LandingMedicOrderSectionSubtitle'>Te facilitamos el acceso a ordenes médicas con  exámenes de laboratorio, imágenes y procedimientos que te permiten detectar precozmente patologías como diabetes, hipertensión, dislipidemia y algunos de los cánceres más frecuentes en la población.</div>
      </div>
      <div className='LandingMedicOrderSectionPackBlockFlex'>
        <div className='LandingMedicOrderSectionPackBlockLeft'>
          <div className="LandingMedicOrderSectionPackBlockImageDiv">
            <LazyLoad once >
            <img alt="" className="LandingMedicOrderSectionPackBlockLeftImage" src={YMCInstructions1}/>
            </LazyLoad>
          </div>
          <div className="LandingMedicOrderSectionPackBlockTexts">
            <h5 className='LandingMedicOrderSectionPackBlockLeftTitle'>Encuentra pack de exámenes por categoria</h5>
            <div className='LandingMedicOrderSectionPackBlockLeftSubtitle'>Hemos diseñado diferentes packs de exámenes útiles para detectar precozmente las enfermedades más comunes de la población, elige entre ellos el que más se acomode a tus necesidades.</div>
            <div className='LandingMedicOrderSectionPackBlockLeftButton' onClick={() => {Navigate("/packs-de-examenes");  document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>Buscar por categoria</div>
          </div>
        </div>
        <div className='LandingMedicOrderSectionPackBlockRight'>
          <div className="LandingMedicOrderSectionPackBlockImageDiv">
            <LazyLoad once >
            <img alt="" className="LandingMedicOrderSectionPackBlockRightImage" src={YMCInstructions3}/>
            </LazyLoad>
          </div>
          <div className="LandingMedicOrderSectionPackBlockTexts">
            <h5 className='LandingMedicOrderSectionPackBlockRightTitle'>Descubre el pack recomendado para tu perfil médico</h5>
            <div className='LandingMedicOrderSectionPackBlockRightSubtitle'>Tras una breve encuesta de salud podremos personalizar tu orden médica, con los exámenes de laboratorio, imagen y procedimientos médicos recomendados especialmente para ti.</div>
            <div className='LandingMedicOrderSectionPackBlockRightButton' onClick={() => {Navigate("/recomendador/paso-1");  document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>Orden medica personalizada</div>
          </div>
        </div>
      </div>
      <div className='LandingMedicOrderSectionTabContent'>
        <LazyLoad once >
        <div className='LandingMedicOrderSectionTabContentFlex'>
            <div className="LandingMedicOrderSectionTabContentBlockFemale" style={{backgroundImage:"linear-gradient(90deg, rgba(102,51,255,1) 20%, rgba(255,255,255,0) 100%),url('https://static.yomecontrolo.cl/public/img/home/FemaleTabLeft.png')",backgroundPosition: 'top center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>

              <div className="LandingMedicOrderSectionTabContentBlockText">Packs Exámenes Mujeres&nbsp;</div>
              <div className="LandingMedicOrderSectionTabContentBlockButton" onClick={() => {SetChosenPackGender("F"); setGenderPack(true); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>Ir a Exámenes</div>

            </div>
            <div className="LandingMedicOrderSectionTabContentBlockMale" style={{backgroundImage:"linear-gradient(90deg, rgba(40,174,255,1) 20%, rgba(255,255,255,0) 100%),url('https://static.yomecontrolo.cl/public/img/home/MaleTabRight.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>

              <div className="LandingMedicOrderSectionTabContentBlockText">Packs Exámenes Hombres </div>
              <div className="LandingMedicOrderSectionTabContentBlockButton" onClick={() => {SetChosenPackGender("M"); setGenderPack(true); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}} >Ir a Exámenes</div>

            </div>
        </div>
        </LazyLoad>
        {/*<div className='LandingMedicOrderSectionTabContentRegister'><span onClick={() => executeScroll()} style={{cursor:"pointer",textDecoration:"underline"}}>Solicita nuestro e-book</span> y obtén un 50% de descuento en tu primera compra.</div>*/}
      </div>
      <MakeItEasy/>
      <div className='LandingWeAreYMCDiv' style={{backgroundImage:"linear-gradient(-90deg, rgba(255,255,255,0.7), rgba(255,255,255,0) 100%),url('https://static.yomecontrolo.cl/public/img/home/BackgroundHueYMC.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
        <div className='LandingWeAreYMCInnerDiv'>
          <div className='LandingWeAreYMCLeftSide'>
            {/*  <div className='LandingWeAreYMCUpTitle'>Somos YMC</div>*/}
            <h1 className='LandingWeAreYMCTitle'>Yo me controlo, para vivir más y mejor</h1>
            <div className='LandingWeAreYMCSubtitle'>Startup chilena que se dedica a revolucionar la atención médica personal y familiar a través de soluciones digitales avanzadas.
                Nuestra misión es acortar las brechas en la atención médica y evolucionar la medicina preventiva hacia la medicina predictiva.</div>
            <div className='LandingWeAreYMCFlexDiv'>
              <div className='LandingWeAreYMCFlexedButtonLeft' onClick={() => {window.scrollTo(0, 0); Navigate("/quienes-somos");}}>Conócenos Más</div>
              <div className='LandingWeAreYMCFlexedButtonRight' onClick={() => {window.scrollTo(0, 0); Navigate("/contacto")}}>Hablémos</div>
            </div>
          </div>
          <div className='LandingWeAreYMCRightSide'>
            <img alt="" className="LandingWeAreYMCRightSideImage" src="https://static.yomecontrolo.cl/public/icons/Appicon.png"/>
          </div>
        </div>
      </div>
      <DidYouKnow/>
      </div>
      <div>
      <Newsletter/>
      </div>
      <YouCanTrust/>
      {/*
      <div className='LandingValueProposalDiv' style={{backgroundImage:"url(" + YMCBlockImage6 + ")",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
        {<div className='LandingValueProposalUpTitle'>Propuesta de Valor</div>}
        <div className='LandingValueProposalTitle'>Las ventajas de preferirnos</div>
        <div className='LandingValueProposalFlexedBoxes'>
          <div className='LandingValueProposalWhiteBox'>
            <img alt="" className='LandingValueProposalImage' src={YMCSwirlyLines1}/>
            <div className='LandingValueProposalWhiteBoxTitle'>Medicina Preventiva</div>
            <div className="LandingValueProposalWhiteBoxSubtitle" id={"desc1".concat("More")}>{description1.split(' ').slice(0,14).join(' ')} &nbsp; <br/>
                <span className='LandingValueProposalWhiteBoxTextOption' onClick={() => toggleDescription("desc1","More")}>Leer más...</span>
            </div>
            <div className="LandingValueProposalWhiteBoxSubtitle hidden" id={"desc1".concat("Less")}>{description1} &nbsp;
                <span className='LandingValueProposalWhiteBoxTextOption' onClick={() => toggleDescription("desc1","Less")}>Leer menos...</span>
            </div>
          </div>
          <div className='LandingValueProposalWhiteBox'>
          <img alt="" className='LandingValueProposalImage' src={YMCSwirlyLines2}/>
            <div className='LandingValueProposalWhiteBoxTitle'>Ruta del Bienestar</div>
            <div className="LandingValueProposalWhiteBoxSubtitle" id={"desc2".concat("More")}>{description2.split(' ').slice(0,14).join(' ')} &nbsp; <br/>
                <span className='LandingValueProposalWhiteBoxTextOption' onClick={() => toggleDescription("desc2","More")}>Leer más...</span>
            </div>
            <div className="LandingValueProposalWhiteBoxSubtitle hidden" id={"desc2".concat("Less")}>{description2} &nbsp;
                <span className='LandingValueProposalWhiteBoxTextOption' onClick={() => toggleDescription("desc2","Less")}>Leer menos...</span>
            </div>
          </div>
          <div className='LandingValueProposalWhiteBox'>
          <img alt="" className='LandingValueProposalImage' src={YMCSwirlyLines3}/>
            <div className='LandingValueProposalWhiteBoxTitle'>Adherencia al tratamiento</div>
            <div className="LandingValueProposalWhiteBoxSubtitle" id={"desc3".concat("More")}>{description3.split(' ').slice(0,14).join(' ')} &nbsp; <br/>
                <span className='LandingValueProposalWhiteBoxTextOption' onClick={() => toggleDescription("desc3","More")}>Leer más...</span>
            </div>
            <div className="LandingValueProposalWhiteBoxSubtitle hidden" id={"desc3".concat("Less")}>{description3} &nbsp;
                <span className='LandingValueProposalWhiteBoxTextOption' onClick={() => toggleDescription("desc3","Less")}>Leer menos...</span>
            </div>
          </div>
          <div className='LandingValueProposalWhiteBox'>
          <img alt="" className='LandingValueProposalImage' src={YMCSwirlyLines4}/>
            <div className='LandingValueProposalWhiteBoxTitle'>Categorización Inteligente</div>
            <div className='LandingValueProposalWhiteBoxSubtitle'>Podemos ayudar con intervenciones personalizada y a tiempo en la población.</div>
          </div>
        </div>
      </div>
      <div className='LandingSelfCareDiv' style={{backgroundImage:"url(" + YMCSelfCareImage + ")",backgroundPosition: 'center',backgroundPositionX: '45%',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
        <div className='LandingSelfCareRightside'>
          <div className='LandingSelfCareUpTitle'>Planificación de autocuidado</div>
          <div className='LandingSelfCareTitle'>Ruta del Bienestar</div>
          <div className='LandingSelfCareSubtitle'>Prevenir nunca fue tan fácil. Al crear tu perfil, te entregamos una cadena completa de exámenes, atenciones y derivaciones para gestionar tu cuidado y extender la esperanza de vida (mejorando la calidad de los años dorados) de manera trazable y predecible.</div>
          <div className='LandingSelfCareFlexedButtons'>
            <div className='LandingSelfCareButtonLeft'>Crear Tu Ruta</div>
            <div className='LandingSelfCareButtonRight'>Conocer Más</div>
          </div>
        </div>
      </div>
      */}
      <div className='ScrollBackToTopOuter ScrollBackToTopHidden' id="ToTheTop">
        <div className='ScrollBackToTop' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
          <FontAwesomeIcon 
            icon={faUpLong} 
            className='ScrollBackToTopUpArrow'
          />
        </div>
      </div>
    </div>
  );
}

export default LandingSlider; 
