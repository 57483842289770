import React, { useState, useEffect, useContext } from 'react';
import "../../Styles/DiabetesRiskCalculator.css";
//import { useNavigate } from 'react-router-dom';
import { UserInfo } from "../Context/UserInfoContext";
import { MedicalContext } from '../Context/MedicalContext';
import Newsletter from "../ContactRoute/Newsletter"
import DiabetesRiskCalc from './DiabetesRiskCode';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom';


function DiabetesRiskCalculator() {
  const Navigate = useNavigate();
  const MySwal = withReactContent(Swal)

  const [ArrowUp, setArrowUp] = useState(false)
  const [Age,setAge] = useState(40)
  const [AbdominalPerimeter,setAbdominalPerimeter] = useState("")
  const [Exercise, setExercise] = useState("");
  const [FruitsOrVegetables, setFruitsOrVegetables] = useState("");
  const [HipertensionMeds, setHipertensionMeds] = useState("");
  const [Hyperglycemia, setHyperglycemia] = useState("");
  const [FamilyMember, setFamilyMember] = useState("");
  const [Parents, setParents] = useState(0);
  const [Siblings, setSiblings] = useState(0);
  const [Children, setChildren] = useState(0);
  const [Cousins, setCousins] = useState(0);
  const [UnclesAndAunts, setUnclesAndAunts] = useState(0);
  const [Grandparents, setGrandparents] = useState(0);
  const [IMC,setIMC] = useState();
  const [CloseFamilyDiabetes, setCloseFamilyDiabetes] = useState(0);
  const [DistantFamilyDiabetes, setDistantFamilyDiabetes] = useState(0);
  const [Calculated, setCalculated] = useState(false)
  const [RiskPoints, setRiskPoints] = useState(0);
  const [RiskCategory, setRiskCategory] = useState("");
  const [OneTime, setOneTime] = useState(false)
  const [FirstPartOfSurveyDone, setFirstPartOfSurveyDone] = useState(0)
  const [TaskDone, setTaskDone] = useState(false)

  const {
    Weight, setWeight, Height, setHeight, Gender, setGender
  } = useContext(UserInfo);

  const {
    PerRecordsCodes
  } = useContext(MedicalContext);

  const [validation, setvalidation] = useState({
    "Gender": "valid",
    "Weight": "valid",
    "Height": "valid",
    "AbdominalPerimeter" : "valid"
  });
  const [validation2, setvalidation2] = useState({
    "Q1": "valid",
    "Q2": "valid",
    "Q3": "valid",
    "Q4" : "valid",
    "Q5" : "valid",
    "Tiles" : "valid"
  });

  function CalculateDiabetesRisk() {
    let answers = [Exercise,FruitsOrVegetables,HipertensionMeds,Hyperglycemia,FamilyMember]
    let ideal = ["valid","valid","valid","valid","valid","valid"]
    for(let i=0; i<answers.length; i++) {
        if(answers[i].length === 0) {
            ideal[i] = "invalid"
        }
    }
    if(ideal[4] === "valid" && FamilyMember === 1) {
        if(Parents+Siblings+Children+Cousins+UnclesAndAunts+Grandparents === 0) {
            ideal[5] = "invalid"
        }
    }

    setvalidation2({
        "Q1": ideal[0],
        "Q2": ideal[1],
        "Q3": ideal[2],
        "Q4" : ideal[3],
        "Q5" : ideal[4],
        "Tiles" : ideal[5]
    })
    
    if(!ideal.includes('invalid')) {
        setRiskPoints(
            DiabetesRiskCalc(
            Gender === 0 ? "M" : "F",
            Age,
            IMC,
            AbdominalPerimeter,
            Exercise,
            FruitsOrVegetables,
            HipertensionMeds,
            Hyperglycemia,
            CloseFamilyDiabetes,
            DistantFamilyDiabetes));
        setOneTime(true);
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    }
  }

    useEffect(() =>{
        if (Parents || Siblings || Children) {
            setCloseFamilyDiabetes(1);
        }
        else {
            setCloseFamilyDiabetes(0);
        }
    }, [Parents, Children, Siblings]
    )

    useEffect(() =>{
        if (Grandparents || UnclesAndAunts || Cousins) {
            setDistantFamilyDiabetes(1);
        }
        else {
            setDistantFamilyDiabetes(0);
        }
    }, [Grandparents, UnclesAndAunts, Cousins]
    )

    useEffect(() =>{
        const IMC = Weight/(Height/100)**2
        setIMC(IMC);
    }, [Height, Weight]
    )

  function IncreaseHeight() {
    setHeight(parseInt(Height) + 1);
  } 

  function DescreaseHeight() {
    setHeight(parseInt(Height) - 1);
  }

  function IncreaseWeight() {
    setWeight(parseInt(Weight) + 1);
  }

  function DescreaseWeight(){
    setWeight(parseInt(Weight) - 1);
  }

  function CalculateMedHis() {
    let tmpList = []
    let which = 0
    switch(Age) {
      case 15:
        if(IMC >= 22.8 && IMC < 27.5) {
          which = 1
        } else if(IMC >= 27.5) {
          which = 2
        }
        break;
      case 16:
        if(IMC >= 23.6 && IMC < 28.4) {
          which = 1
        } else if(IMC >= 28.4) {
          which = 2
        }
        break;
      case 17:
        if(IMC >= 24.4 && IMC < 29.1) {
          which = 1
        } else if(IMC >= 29.1) {
          which = 2
        }
        break;
      default:
        if(IMC >= 25 && IMC < 30) {
          which = 1
        } else if(IMC >= 30) {
          which = 2
        }
        break;
    }
    if(which === 1) {
      tmpList.push(PerRecordsCodes["Sobrepeso"])
    } else if(which === 2) {
      tmpList.push(PerRecordsCodes["Obesidad"])
    }
    return tmpList
  }

  function GenderChange(sex) {
    let tmp1 = document.getElementById("MaleTile")
    let tmp1I = document.getElementById("MaleTileIcon")
    let tmp2 = document.getElementById("FemaleTile")
    let tmp2I = document.getElementById("FemaleTileIcon")
    if(sex !== "F") {
        if(tmp2.className.includes("DiabetesRiskCalculatorRightSideSurveyQ1ActiveTile")) {
            tmp2.className = tmp2.className.replace(" DiabetesRiskCalculatorRightSideSurveyQ1ActiveTile","")
            tmp2I.className = tmp2I.className.replace(" DiabetesRiskCalculatorRightSideSurveyQ1TileActiveIcon","")
        }
        tmp1.className += " DiabetesRiskCalculatorRightSideSurveyQ1ActiveTile"
        tmp1I.className += " DiabetesRiskCalculatorRightSideSurveyQ1TileActiveIcon"
        setGender(1)
    }
    if(sex !== "M") {
        if(tmp1.className.includes("DiabetesRiskCalculatorRightSideSurveyQ1ActiveTile")) {
            tmp1.className = tmp1.className.replace(" DiabetesRiskCalculatorRightSideSurveyQ1ActiveTile","")
            tmp1I.className = tmp1I.className.replace(" DiabetesRiskCalculatorRightSideSurveyQ1TileActiveIcon","")
        }
        tmp2.className += " DiabetesRiskCalculatorRightSideSurveyQ1ActiveTile"
        tmp2I.className += " DiabetesRiskCalculatorRightSideSurveyQ1TileActiveIcon"
        setGender(2)
    }
  }

  function ExerciseChange(answer){
    let tmp1 = document.getElementById("ExerciseTile")
    let tmp2 = document.getElementById("NoExerciseTile")
    if (answer !== "NO"){
        if(tmp2.className.includes("DiabetesRiskCalculatorPart2SurveyQ1ActiveTile")) tmp2.className = tmp2.className.replace(" DiabetesRiskCalculatorPart2SurveyQ1ActiveTile","")
        tmp1.className += " DiabetesRiskCalculatorPart2SurveyQ1ActiveTile"
        setExercise(1)
    }
    if (answer !== "YES"){
        if(tmp1.className.includes("DiabetesRiskCalculatorPart2SurveyQ1ActiveTile")) tmp1.className = tmp1.className.replace(" DiabetesRiskCalculatorPart2SurveyQ1ActiveTile","")
        tmp2.className += " DiabetesRiskCalculatorPart2SurveyQ1ActiveTile"
        setExercise(0)
    }
  }

  function FruitsOrVegetablesChange(answer){
    let tmp1 = document.getElementById("FruitsOrVegetablesTile")
    let tmp2 = document.getElementById("NoFruitsOrVegetablesTile")
    if (answer !== "NO"){
        if(tmp2.className.includes("DiabetesRiskCalculatorPart2SurveyQ1ActiveTile")) tmp2.className = tmp2.className.replace(" DiabetesRiskCalculatorPart2SurveyQ1ActiveTile","")
        tmp1.className += " DiabetesRiskCalculatorPart2SurveyQ1ActiveTile"
        setFruitsOrVegetables(1)
    }
    if (answer !== "YES"){
        if(tmp1.className.includes("DiabetesRiskCalculatorPart2SurveyQ1ActiveTile")) tmp1.className = tmp1.className.replace(" DiabetesRiskCalculatorPart2SurveyQ1ActiveTile","")
        tmp2.className += " DiabetesRiskCalculatorPart2SurveyQ1ActiveTile"
        setFruitsOrVegetables(0)
    }
  }

  function HipertensionMedsChange(answer){
    let tmp1 = document.getElementById("HipertensionMedsTile")
    let tmp2 = document.getElementById("NoHipertensionMedsTile")
    if (answer !== "NO"){
        if(tmp2.className.includes("DiabetesRiskCalculatorPart2SurveyQ1ActiveTile")) tmp2.className = tmp2.className.replace(" DiabetesRiskCalculatorPart2SurveyQ1ActiveTile","")
        tmp1.className += " DiabetesRiskCalculatorPart2SurveyQ1ActiveTile"
        setHipertensionMeds(1)
    }
    if (answer !== "YES"){
        if(tmp1.className.includes("DiabetesRiskCalculatorPart2SurveyQ1ActiveTile")) tmp1.className = tmp1.className.replace(" DiabetesRiskCalculatorPart2SurveyQ1ActiveTile","")
        tmp2.className += " DiabetesRiskCalculatorPart2SurveyQ1ActiveTile"
        setHipertensionMeds(0)
    }
  }
  
  function HyperglycemiaChange(answer){
    let tmp1 = document.getElementById("HyperglycemiaTile")
    let tmp2 = document.getElementById("NoHyperglycemiaTile")
    if (answer !== "NO"){
        if(tmp2.className.includes("DiabetesRiskCalculatorPart2SurveyQ1ActiveTile")) tmp2.className = tmp2.className.replace(" DiabetesRiskCalculatorPart2SurveyQ1ActiveTile","")
        tmp1.className += " DiabetesRiskCalculatorPart2SurveyQ1ActiveTile"
        setHyperglycemia(1)
    }
    if (answer !== "YES"){
        if(tmp1.className.includes("DiabetesRiskCalculatorPart2SurveyQ1ActiveTile")) tmp1.className = tmp1.className.replace(" DiabetesRiskCalculatorPart2SurveyQ1ActiveTile","")
        tmp2.className += " DiabetesRiskCalculatorPart2SurveyQ1ActiveTile"
        setHyperglycemia(0)
    }
  }

  function FamilyMemberChange(answer) {
    let tmp1 = document.getElementById("FamilyMemberTile")
    let tmp2 = document.getElementById("NoFamilyMemberTile")
    if (answer !== "NO"){
        if(tmp2.className.includes("DiabetesRiskCalculatorPart2SurveyQ1ActiveTile")) tmp2.className = tmp2.className.replace(" DiabetesRiskCalculatorPart2SurveyQ1ActiveTile","")
        tmp1.className += " DiabetesRiskCalculatorPart2SurveyQ1ActiveTile"
        setFamilyMember(1)
    }
    if (answer !== "YES"){
        if(tmp1.className.includes("DiabetesRiskCalculatorPart2SurveyQ1ActiveTile")) tmp1.className = tmp1.className.replace(" DiabetesRiskCalculatorPart2SurveyQ1ActiveTile","")
        tmp2.className += " DiabetesRiskCalculatorPart2SurveyQ1ActiveTile"
        setFamilyMember(0)
        setParents(0)
        setChildren(0)
        setSiblings(0)
        setGrandparents(0)
        setUnclesAndAunts(0)
        setCousins(0)
    }
  }

  function ParentsChange() {
    let tmp = document.getElementById("ParentsTile");
    if (tmp.className.includes("DiabetesRiskCalculatorPart2FamilyMemberActive")) {
        tmp.className = tmp.className.replace(" DiabetesRiskCalculatorPart2FamilyMemberActive","");
        setParents(0);
    }
    else {
        tmp.className += " DiabetesRiskCalculatorPart2FamilyMemberActive";
        setParents(1);
    }
  }

  function SiblingsChange() {
    let tmp = document.getElementById("SiblingsTile");
    if (tmp.className.includes("DiabetesRiskCalculatorPart2FamilyMemberActive")) {
        tmp.className = tmp.className.replace(" DiabetesRiskCalculatorPart2FamilyMemberActive","");
        setSiblings(0);
    }
    else {
        tmp.className += " DiabetesRiskCalculatorPart2FamilyMemberActive";
        setSiblings(1);
    }
  }

  function ChildrenChange() {
    let tmp = document.getElementById("ChildrenTile");
    if (tmp.className.includes("DiabetesRiskCalculatorPart2FamilyMemberActive")) {
        tmp.className = tmp.className.replace(" DiabetesRiskCalculatorPart2FamilyMemberActive","");
        setChildren(0);
    }
    else {
        tmp.className += " DiabetesRiskCalculatorPart2FamilyMemberActive";
        setChildren(1);
    }
  }

  function GrandparentsChange() {
    let tmp = document.getElementById("GrandparentsTile");
    if (tmp.className.includes("DiabetesRiskCalculatorPart2FamilyMemberActive")) {
        tmp.className = tmp.className.replace(" DiabetesRiskCalculatorPart2FamilyMemberActive","");
        setGrandparents(0);
    }
    else {
        tmp.className += " DiabetesRiskCalculatorPart2FamilyMemberActive";
        setGrandparents(1);
    }
  }

  function UnclesAndAuntsChange() {
    let tmp = document.getElementById("UnclesAndAuntsTile");
    if (tmp.className.includes("DiabetesRiskCalculatorPart2FamilyMemberActive")) {
        tmp.className = tmp.className.replace(" DiabetesRiskCalculatorPart2FamilyMemberActive","");
        setUnclesAndAunts(0);
    }
    else {
        tmp.className += " DiabetesRiskCalculatorPart2FamilyMemberActive";
        setUnclesAndAunts(1);
    }
  }

  function CousinsChange() {
    let tmp = document.getElementById("CousinsTile");
    if (tmp.className.includes("DiabetesRiskCalculatorPart2FamilyMemberActive")) {
        tmp.className = tmp.className.replace(" DiabetesRiskCalculatorPart2FamilyMemberActive","");
        setCousins(0);
    }
    else {
        tmp.className += " DiabetesRiskCalculatorPart2FamilyMemberActive";
        setCousins(1);
    }
  }

  const handleChange = (value, setValue) => {
    const result = value.replace(/\D/g, '');
    setValue(result.slice(0, 3));
  };

  function HandleSubmitFirstPart() {
    let isGenderValid = Gender !== 1 && Gender !== 2 ? "invalid" : "valid";
    let isWeightValid = String(Weight).trim() === "" ? "invalid" : "valid";
    let isHeightValid = String(Height).trim() === "" ? "invalid" : "valid";
    let isAbdominalPerimeterValid = "valid";

    if(Weight === "" || Weight < 2 || Weight > 650) {
        isWeightValid = "invalid"
      }
    if(Height === "" || Height < 54 || Height > 272) {
        isHeightValid = "invalid"
    }

    if(AbdominalPerimeter === "" || AbdominalPerimeter < 30) {
        isAbdominalPerimeterValid = "invalid"
    }

    setvalidation({
        "Gender": isGenderValid,
        "Weight": isWeightValid,
        "Height": isHeightValid,
        "AbdominalPerimeter": isAbdominalPerimeterValid
    });

    if (isGenderValid === "valid" && isWeightValid === "valid" && isHeightValid === "valid" && isAbdominalPerimeterValid === "valid"){
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
        setFirstPartOfSurveyDone(1);
    }
  }

  function GoBack() {
    setExercise(0);
    setFruitsOrVegetables(0);
    setHipertensionMeds(0);
    setHyperglycemia(0);
    setFamilyMember(0);
    setParents(0);
    setChildren(0);
    setSiblings(0);
    setGrandparents(0);
    setUnclesAndAunts(0);
    setCousins(0);
    setFirstPartOfSurveyDone(0);
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
  }

    useEffect(() => {
        if(OneTime) {
            if(RiskPoints < 7) {
                setRiskCategory("Muy Bajo")
            } else if(RiskPoints < 12) {
                setRiskCategory("Bajo")
            } else if(RiskPoints < 15) {
                setRiskCategory("Moderado")
            } else if(RiskPoints < 20) {
                setRiskCategory("Alto")
            } else {
                setRiskCategory("Muy Alto")
            }
            setCalculated(true);
            setOneTime(false)
        }

        if(TaskDone) {
            MySwal.close()
            Navigate("/recomendador/paso-4")
        }
    },[OneTime, RiskPoints, TaskDone, MySwal, Navigate])

    function displayBiblio() {
        let tmp1 = document.getElementById('BiblioContent')
        if(tmp1.className.includes('HeartAgeCalculatorBibliographyContentHidden')) {
            tmp1.className = tmp1.className.replace(" HeartAgeCalculatorBibliographyContentHidden", "");
            setArrowUp(true)
        } else {
            tmp1.className += " HeartAgeCalculatorBibliographyContentHidden"
            setArrowUp(false)
        }
    }

    function toggleDescription(type,num="") {
        let tmp = document.getElementById("More"+num)
        let tmp2 = document.getElementById("Less"+num)
        if(type === "Less") {
            tmp.className += " HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden"
            tmp2.className = tmp2.className.replace(" HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden", "");
        } else {
            tmp.className = tmp.className.replace(" HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden", "");
            tmp2.className += " HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden"
        }
    }
    
    const getRecommendedExams = async() => {
        MySwal.fire({
            position: "center",
            iconColor: "#6633FF",
            imageUrl: "https://static.yomecontrolo.cl/public/icons/ymc-loading.gif",
            title: "Cargando Orden Personalizada...",
            color: "#340066",
            imageWidth: 400,
            imageHeight: 400,
            showConfirmButton: false,
        })

        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0; 
    
        let params = {
          meds: CalculateMedHis(),
          sex: Gender === 1 ? 'M' : 'F',
          age: Age
        };
      
        var requestOptions = {
          method: 'POST',
          redirect: 'follow',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params)
        };
    
        try{
          
        const resp = await fetch("https://ymc-graph-api.azurewebsites.net/getrecommendedexams", requestOptions)
          if (resp.status !== 200){
            MySwal.close()
            alert("there has been some error");
            return false;
          }
          const data = await resp.json();
          //console.log(data)
          
          localStorage.setItem('PreviewRelatedExams', JSON.stringify(data));
    
          setTaskDone(true)
        }
        catch(error){
          MySwal.close()
          console.log("there has been an error login in")
          alert("Ocurrió un error procesando los resultados")
          return false
        }
    }

  return (
    <>
    <div className="DiabetesRiskCalculatorFull" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/BackgroundOneCorner.png')",backgroundPosition: 'left',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
        { !FirstPartOfSurveyDone?
        <div className="DiabetesRiskCalculatorInnerDiv">
            <div className="DiabetesRiskCalculatorLeftSide">
                <div className="DiabetesRiskCalculatorLeftSideUpTitle">Conoce si eres paciente de riesgo</div>
                <div className="DiabetesRiskCalculatorLeftSideTitle">Medio millón de chilenos tiene diabetes y no lo sabe</div>
                <div className="DiabetesRiskCalculatorLeftSideSubtitle">En esta calculadora de salud podrás identificar indicadores de riesgo y conocer tu riesgo a 10 años de padecer diabetes.</div>
                <div className="DiabetesRiskCalculatorLeftSideSubtitle2" id="More">¿A quienes está dirigida esta herramienta? <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("Less")}>Leer más...</span></div>
                <div className="DiabetesRiskCalculatorLeftSideSubtitle2 HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden" id="Less">
                <b>¿A quienes está dirigida esta herramienta?</b>
                <br/>
                El test de FINDRISC está dirigido especialmente a las personas que presentan 2 o más factores de riesgo de diabetes mellitus, Tales como:
                    <ul>
                        <li>Personas que presenten sobrepeso u obesidad.</li>
                        <li>Mayores de 45 años de edad.</li>
                        <li>Antecedentes personales de niveles elevados de glucosa en sangre.</li>
                        <li>Antecedentes familiares de diabetes.</li>
                        <li>Personas sedentarias.</li>
                        <li>Mujeres con antecedente de diabetes gestacional o síndrome de ovario poliquístico.</li>
                        
                    </ul> 
                <br/>
                La escala FINDRISC es un instrumento de cribaje inicialmente diseñado para valorar el riesgo individual de desarrollar DM2 en el plazo de 10 años. Las principales variables que se relacionan con el riesgo de desarrollar DM en esta escala son: edad, IMC, el perímetro de la cintura, hipertensión arterial con tratamiento farmacológico y los antecedentes personales de glucemia elevada.
                <br/><br/>
                Pese a que en esta herramienta no ha sido validad en población chilena, su uso se encuentra  aprobada por la Federación Internacional de Diabetes (FID) y es utilizado en países como EE.UU., España, Grecia, Italia y Colombia  entre otros, para la identificación de personas con mayor riesgo de desarrollar diabetes tipo 2.
                <br/>
                <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("More")}>Leer menos...</span>
                </div>
                <img alt="" className="DiabetesRiskCalculatorLeftSideImage" src="https://static.yomecontrolo.cl/public/img/calculator/diabetes/DiabetesCalculator.png"/>
            </div>
            <div className="DiabetesRiskCalculatorRightSide">
                <div className="DiabetesRiskCalculatorLeftSideResultContainer">
                <div className="DiabetesRiskCalculatorRightSideTitleDiv">
                    <img alt="" className="DiabetesRiskCalculatorRightSideTitleIcon" src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleLogo.png"/>
                    <div className="DiabetesRiskCalculatorRightSideTitle">Calculadora de Salud</div>
                </div>
                <div className="DiabetesRiskCalculatorRightSideSurveyTitle">Test Findrisk</div>
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1">1. Indícanos tu sexo</div>
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1TileFlex">
                    <div className={Gender === 1 ? "DiabetesRiskCalculatorRightSideSurveyQ1Tile DiabetesRiskCalculatorRightSideSurveyQ1ActiveTile" : "DiabetesRiskCalculatorRightSideSurveyQ1Tile"} id="MaleTile" onClick={() => GenderChange("M")}>
                        Hombre <br/>
                        <img alt="" className={Gender === 1 ? "DiabetesRiskCalculatorRightSideSurveyQ1TileIcon DiabetesRiskCalculatorRightSideSurveyQ1TileActiveIcon" : "DiabetesRiskCalculatorRightSideSurveyQ1TileIcon"} src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleMaleIcon.png" id="MaleTileIcon"/>
                    </div>
                    <div className={Gender === 2 ? "DiabetesRiskCalculatorRightSideSurveyQ1Tile DiabetesRiskCalculatorRightSideSurveyQ1ActiveTile" : "DiabetesRiskCalculatorRightSideSurveyQ1Tile"} id="FemaleTile" onClick={() => GenderChange("F")}>
                        Mujer <br/>
                        <img alt="" className={Gender === 2 ? "DiabetesRiskCalculatorRightSideSurveyQ1TileIcon DiabetesRiskCalculatorRightSideSurveyQ1TileActiveIcon" : "DiabetesRiskCalculatorRightSideSurveyQ1TileIcon"} src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleFemaleIconT.png" id="FemaleTileIcon"/>
                    </div>
                </div>
                {validation["Gender"] === "invalid" ?
                    <div className='BloodAlert' style={{ color: "red", fontWeight: "500" }}> Escoja su sexo  </div>
                : null }
                <div className="DiabetesRiskCalculatorRightSideSurveyQuestion">
                    <div className="DiabetesRiskCalculatorRightSideSurveyQ1">2. Indícanos tu edad</div>
                    <select defaultValue={Age} id="YMCAge" className='DoBTile DiabetesRiskCalculatorRightSideSurveyQ1AgeInput rounded-2' onChange={(e) => setAge(parseInt(e.target.value))}>
                    {Array.from({ length: 45 }, (_, i) => i+25).map((day) => (
                        <option key={day} value={parseInt(day)}>{day}</option>
                    ))}
                    </select>
                </div>
                <div className="DiabetesRiskCalculatorRightSideSurveyQuestionsFlex">
                    <div className="DiabetesRiskCalculatorRightSideSurveyQuestion">
                        <div className="DiabetesRiskCalculatorRightSideSurveyQ1">3. Ingresa tu peso </div>
                        <div className="DiabetesRiskCalculatorRightSideSurveyQ1MeasureFlex2">
                            <div className="DiabetesRiskCalculatorRightSideModifier" onClick={() => DescreaseWeight()}> - </div>
                            <input
                                value={Weight}
                                className="DiabetesRiskCalculatorRightSideSurveyQ4InputTile"
                                style={{ border: validation["Weight"] === "invalid" ? "red solid 1px" : "#FBFFFF solid 1px" }}
                                id="YMCWeight"
                                onChange={(e) => handleChange(e.target.value,setWeight)}/>
                            <div className="DiabetesRiskCalculatorRightSideSurveyQ4InputMeasure">kg</div>
                            <div className="DiabetesRiskCalculatorRightSideModifier" onClick={() => IncreaseWeight()}> + </div>
                        </div>
                        {validation["Weight"] === "invalid" ?
                            <div className='WeightAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                        : null }
                    </div>
                    <div className="DiabetesRiskCalculatorRightSideSurveyQuestion">
                        <div className="DiabetesRiskCalculatorRightSideSurveyQ1">4. Ingresa tu altura </div>
                        <div className="DiabetesRiskCalculatorRightSideSurveyQ1MeasureFlex2">
                            <div className="DiabetesRiskCalculatorRightSideModifier" onClick={() => DescreaseHeight()}> - </div>
                            <input
                                value={Height}
                                className="DiabetesRiskCalculatorRightSideSurveyQ4InputTile"
                                style={{ border: validation["Height"] === "invalid" ? "red solid 1px" : "#FBFFFF solid 1px" }}
                                id="YMCHeight"
                                onChange={(e) => handleChange(e.target.value,setHeight)}/>
                            <div className="DiabetesRiskCalculatorRightSideSurveyQ4InputMeasure">cm</div>
                            <div className="DiabetesRiskCalculatorRightSideModifier" onClick={() => IncreaseHeight()}> + </div>
                        </div>
                        {validation["Height"] === "invalid" ?
                            <div className='HeightAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                        : null }
                    </div>
                </div>
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1Flex">
                    <div className="DiabetesRiskCalculatorRightSideSurveyQ1">5. Perímetro abdominal</div>
                </div>
                <div className='DiabetesRiskCalculatorRightSideSurveyAbdominalPerimeterFlex'>
                    <div className="DiabetesRiskCalculatorRightSideSurveyAbdominalPerimeterDiv rounded-1">
                        <div className="DiabetesRiskCalculatorRightSideSurveyQ1MeasureFlex">
                            <input
                                value={AbdominalPerimeter}
                                className="DiabetesRiskCalculatorRightSideSurveyQ5InputTile rounded-1 "
                                style={{ border: validation["AbdominalPerimeter"] === "invalid" ? "red solid 1px" : "#F2F2FF solid 1px" }}
                                id="YMCAbdominalPerimeter"
                                onChange={(e) => handleChange(e.target.value,setAbdominalPerimeter)}
                                placeholder='-'
                                 />
                            <div className="DiabetesRiskCalculatorRightSideSurveyQ4InputMeasure">cm</div>
                        </div>
                    </div>
                    <div className='DiabetesRiskCalculatorRightSideSurveyAbdominalPerimeterExplanation'>* Usa de referencia el punto medio entre la ultima costilla inferior hasta el hueso de la cadera.</div>
                </div>
                {validation["AbdominalPerimeter"] === "invalid" ?
                    <div className='BloodAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                : null }
                <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlocksFlex'>
                    <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockDark'></div>
                    <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockLight'></div>
                    <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockLight'></div>
                    <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockText'>1/3</div>
                </div>
                    <div className="DiabetesRiskCalculatorRightSideSurveyCalculate" onClick={() => HandleSubmitFirstPart()}>Siguiente</div>
                </div>
            </div>
        </div>
        : null }

        {FirstPartOfSurveyDone && !Calculated ?
        <div className="DiabetesRiskCalculatorPart2">
            <div className='DiabetesRiskCalculatorPart2TitleFlex'>
                <img alt="" className="DiabetesRiskCalculatorRightSideTitleIcon" src="https://static.yomecontrolo.cl/public/icons/YMCLogoTransparent.png"/>
                <div className='DiabetesRiskCalculatorPart2RightSide'>
                    <div className="DiabetesRiskCalculatorPart2RightSideTitleFlex">
                        <div className="DiabetesRiskCalculatorPart2Title">Calculadora de Salud</div>
                        <img alt="" className="DiabetesRiskCalculatorRightSideTitleIcon" src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleLogo.png"/>
                    </div>
                    <div className='DiabetesRiskCalculatorPart2RightSideSubtitle'>Responde nuestro evaluador digital para conocer más tu perfil de riesgo personal.</div>
                </div>
            </div>
            <div className="DiabetesRiskCalculatorRightSideSurveyTitlePart2">Test Findrisk</div>
            <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlocksFlex'>
                <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockDark'></div>
                <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockDark'></div>
                <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockLight'></div>
                <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockText'>2/3</div>
            </div>
            <div className="DiabetesRiskCalculatorRightSideSurveyQ1TileFlex">
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1 Part2Question">6. Realiza ejercicio habitualmente</div>
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1Answers">
                    <div className="DiabetesRiskCalculatorPart2SurveyQ1Tile" id="ExerciseTile" onClick={() => ExerciseChange("YES")}>
                        Si 
                    </div>
                    <div className="DiabetesRiskCalculatorPart2SurveyQ1Tile" id="NoExerciseTile" onClick={() => ExerciseChange("NO")}>
                        No
                    </div>
                </div>
            </div>
            {validation2["Q1"] === "invalid" ?
                <div className='YNAlert' style={{ color: "red", fontWeight: "500" }}> Escoja una opción en la pregunta anterior</div>
            : null }
            <div className="DiabetesRiskCalculatorRightSideSurveyQ1TileFlex">
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1 Part2Question">7. ¿Come a diario frutas o verduras?</div>
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1Answers">
                    <div className="DiabetesRiskCalculatorPart2SurveyQ1Tile" id="FruitsOrVegetablesTile" onClick={() => FruitsOrVegetablesChange("YES")}>
                        Si 
                    </div>
                    <div className="DiabetesRiskCalculatorPart2SurveyQ1Tile" id="NoFruitsOrVegetablesTile" onClick={() => FruitsOrVegetablesChange("NO")}>
                        No
                    </div>
                </div>
            </div>
            {validation2["Q2"] === "invalid" ?
                <div className='YNAlert' style={{ color: "red", fontWeight: "500" }}> Escoja una opción en la pregunta anterior</div>
            : null }
            <div className="DiabetesRiskCalculatorRightSideSurveyQ1TileFlex">
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1 Part2Question">8. ¿Toma medicación para la hipertensión regularmente?</div>
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1Answers">
                    <div className="DiabetesRiskCalculatorPart2SurveyQ1Tile" id="HipertensionMedsTile" onClick={() => HipertensionMedsChange("YES")}>
                        Si 
                    </div>
                    <div className="DiabetesRiskCalculatorPart2SurveyQ1Tile" id="NoHipertensionMedsTile" onClick={() => HipertensionMedsChange("NO")}>
                        No
                    </div>
                </div>
            </div>
            {validation2["Q3"] === "invalid" ?
                <div className='YNAlert' style={{ color: "red", fontWeight: "500" }}> Escoja una opción en la pregunta anterior</div>
            : null }
            <div className="DiabetesRiskCalculatorRightSideSurveyQ1TileFlex">
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1 Part2Question">9. ¿Le han encontrado alguna vez valores de glicemia altos?</div>
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1Answers">
                    <div className="DiabetesRiskCalculatorPart2SurveyQ1Tile" id="HyperglycemiaTile" onClick={() => HyperglycemiaChange("YES")}>
                        Si 
                    </div>
                    <div className="DiabetesRiskCalculatorPart2SurveyQ1Tile" id="NoHyperglycemiaTile" onClick={() => HyperglycemiaChange("NO")}>
                        No
                    </div>
                </div>
            </div>
            {validation2["Q4"] === "invalid" ?
                <div className='YNAlert' style={{ color: "red", fontWeight: "500" }}> Escoja una opción en la pregunta anterior</div>
            : null }
            <div className="DiabetesRiskCalculatorRightSideSurveyQ1TileFlex">
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1 Part2Question">10. ¿Algún familiar está diagnosticado de diabetes (tipo I o II)?</div>
                <div className="DiabetesRiskCalculatorRightSideSurveyQ1Answers">
                    <div className="DiabetesRiskCalculatorPart2SurveyQ1Tile" id="FamilyMemberTile" onClick={() => FamilyMemberChange("YES")}>
                        Si 
                    </div>
                    <div className="DiabetesRiskCalculatorPart2SurveyQ1Tile" id="NoFamilyMemberTile" onClick={() => FamilyMemberChange("NO")}>
                        No
                    </div>
                </div>
            </div>
            {FamilyMember?
                <div className='DiabetesRiskCalculatorPart2FamilyFlex'>
                    <div className='DiabetesRiskCalculatorPart2FamilyMember' id="ParentsTile" onClick={() => ParentsChange()}>Padres</div>
                    <div className='DiabetesRiskCalculatorPart2FamilyMember' id="SiblingsTile" onClick={() => SiblingsChange()}>Hermanos</div>
                    <div className='DiabetesRiskCalculatorPart2FamilyMember' id="ChildrenTile" onClick={() => ChildrenChange()}>Hijos</div>
                    <div className='DiabetesRiskCalculatorPart2FamilyMember' id="GrandparentsTile" onClick={() => GrandparentsChange()}>Abuelos</div>
                    <div className='DiabetesRiskCalculatorPart2FamilyMember' id="UnclesAndAuntsTile" onClick={() => UnclesAndAuntsChange()}>Tios</div>
                    <div className='DiabetesRiskCalculatorPart2FamilyMember' id="CousinsTile" onClick={() => CousinsChange()}>Primos</div>
                </div>
            : null}
            {validation2["Q5"] === "invalid" ?
                <div className='YNAlert' style={{ color: "red", fontWeight: "500" }}> Escoja una opción en la pregunta anterior</div>
            : validation2["Tiles"] === "invalid" ?
                <div className='YNAlert' style={{ color: "red", fontWeight: "500" }}> Escoja las opciones que indiquen cuál/es de sus familiares tienen diabetes </div>
            : null }
            <div className='DiabetesRiskCalculatorPart2ButtonsFlex'>
                <div className="GeneralWhiteButtonFlex DiabetesRiskCalculatorPart2Button" onClick={() => {GoBack(); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
                    <img alt="" className="GeneralPurpleButtonIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleExamIcon.png"/>
                    <div className="GeneralWhiteButtonText DiabetesRiskCalculatorPart2SurveyButtonText">Volver Atrás</div>
                </div>
                <div className="GeneralPurpleButtonFlex DiabetesRiskCalculatorPart2Button" onClick={() => CalculateDiabetesRisk()}>
                    <div className="GeneralPurpleButtonText DiabetesRiskCalculatorPart2SurveyButtonText">Siguiente</div>
                    <img alt="" className="GeneralPurpleButtonIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteSearchIcon.png"/>
                </div>
            </div>
        </div>
        : null}
    {Calculated ?
        <div className='DiabetesRiskCalculatorResults'>
            <div className='DiabetesRiskCalculatorResultsTitleFlex'>
                <div className='DiabetesRiskCalculatorResultsTitleInnerFlex'>
                    <img alt="" className='DiabetesRiskCalculatorResultsTitleLeftSideImage' src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleLogo.png"/>
                    <div className='DiabetesRiskCalculatorResultsTitleBlock'>
                        <div className='DiabetesRiskCalculatorResultsUpTitle'>Calcula tu riesgo de padecer Diabetes</div>
                        <h2 className='DiabetesRiskCalculatorResultsTitle'>Resultados</h2>
                    </div>
                </div>
                <img alt="" className='DiabetesRiskCalculatorResultsTitleRightSideImage' src="https://static.yomecontrolo.cl/public/icons/YMCLogoTransparent.png"/>
            </div>
            <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlocksFlex'>
                <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockDark'></div>
                <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockDark'></div>
                <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockDark'></div>
                <div className='DiabetesRiskCalculatorRightSideSurveyAdvanceBlockText'>3/3</div>
            </div>
            <div className='DiabetesRiskCalculatorResultsPart2'>
                <h5 className='DiabetesRiskCalculatorResults2Title DiabetesRiskCalculatorResultsTextPadding' style={{textAlign:"center"}}>¿Qué riesgo tiene usted de desarrollar diabetes tipo 2?</h5>
                {RiskPoints < 12 ?
                    <>
                    <div className='DiabetesRiskCalculatorResultsTextSmallScreen LowRisk'>
                        <div className='DiabetesRiskCalculatorResultsTextPart1'>Riesgo {RiskCategory}</div> 
                        <div className='DiabetesRiskCalculatorResultsTextPart3'>{RiskPoints} pts</div> 
                    </div>
                    <div className='DiabetesRiskCalculatorResultsContainer' style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/packs/DM00YMC020.png')",backgroundPosition: 'left',backgroundRepeat: 'no-repeat'}}>
                        <div className='DiabetesRiskCalculatorResultsText LowRisk'>
                            <div className='DiabetesRiskCalculatorResultsTextPart1'>Riesgo {RiskCategory}</div> 
                            <div className='DiabetesRiskCalculatorResultsTextPart3'>{RiskPoints} pts</div> 
                        </div>
                        <div className="HeartAgeCalculatorLeftSideResultHeartBlockMeterBlock">
                            <div clasName="DiabetesRiskCalculatorResultsRiskBar" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/RiskMeterHorizontalSimplified.png')",backgroundPosition: 'left',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', width: "100%"}}>
                                <div className='DiabetesRiskCalculatorResultsCircleContain'>
                                    <img alt="" className="DiabetesMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_LowRiskFace.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DiabetesResultSubtext HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden" id="Less1">
                        En este momento su riesgo de tener diabetes tipo 2 en un plazo de 10 años es {RiskCategory}. Sin embargo, su riesgo cambia a lo largo del tiempo y aumenta con la edad.<br/><br/>
                        Hoy 2,5 millones de chilenos sufren esta patología. Estudios nacionales han reportado que más de la mitad de los pacientes diabéticos son muchas veces diagnosticados tarde, es decir, cuando ya presentaban síntomas de que la enfermedad se había desarrollado. Asegúrate de realizar tus exámenes preventivos regularmente y mantener hábitos de vida saludables para prevenir o retrasar el desarrollo de enfermedades como la diabetes y la enfermedad renal crónica.
                        <br/> 
                        <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("More",1)}>Leer menos...</span>
                    </div>
                    <div className="DiabetesResultSubtext" id="More1">
                        En este momento su riesgo de tener diabetes tipo 2 en un plazo de 10 años es {RiskCategory}. Sin embargo, su riesgo cambia a lo largo del tiempo y aumenta con la edad.
                        <br/><br/>
                        <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("Less",1)}>Leer más...</span>
                    </div>
                    <div className='DiabetesResultMedicalOrderContainer' style={{paddingInline:"2%"}}>
                        <div className='GeneralPurpleButtonFlex DiabetesResultMedicalOrder' onClick={() => getRecommendedExams()}>
                            Generar Órden Médica Personalizada
                        </div>
                    </div>
                    </>
                : RiskPoints >= 12 && RiskPoints <=14 ? 
                    <>
                    <div className='DiabetesRiskCalculatorResultsTextSmallScreen MediumRisk'>
                        <div className='DiabetesRiskCalculatorResultsTextPart1'>Riesgo {RiskCategory}</div>
                        <div className='DiabetesRiskCalculatorResultsTextPart3'>{RiskPoints} pts</div> 
                    </div>
                    <div className='DiabetesRiskCalculatorResultsContainer2' style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/packs/DM00YMC020.png')",backgroundPosition: 'left',backgroundRepeat: 'no-repeat'}}>
                        <div className='DiabetesRiskCalculatorResultsText MediumRisk'>
                            <div className='DiabetesRiskCalculatorResultsTextPart1'>Riesgo {RiskCategory}</div>
                            <div className='DiabetesRiskCalculatorResultsTextPart3'>{RiskPoints} pts</div>
                        </div>
                        <div className="HeartAgeCalculatorLeftSideResultHeartBlockMeterBlock">
                            <div className="DiabetesRiskCalculatorResultsRiskBar" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/RiskMeterHorizontalSimplified.png')",backgroundPosition: 'left',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', width: "100%"}}>
                                <div className='DiabetesRiskCalculatorResultsCircleContain' style={{justifyContent: "center"}}>
                                    <img alt="" className="DiabetesMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="DiabetesResultSubtext HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden" id="Less1">
                        En este momento su riesgo de tener diabetes tipo 2 en un plazo de 10 años es {RiskCategory}. Esto significa que de cada 100 personas con el mismo puntaje, 17 desarrollarán diabetes en un plazo de 10 años, tú podrías ser una de ellas.<br/><br/>
                        Las intervenciones para prevenir o retrasar la diabetes tipo 2 en personas con prediabetes son factibles y económicas. En diversos estudios se ha determinado que las intervenciones de estilo de vida son más económicas que los medicamentos.<br/><br/>
                        La falta de diagnóstico oportuno es una importante causa de desarrollo prematuro de las formas más severas de DM2. Se ha demostrado que el manejo temprano de pacientes con DM2 retrasa y reduce la incidencia de complicaciones derivadas de la enfermedad.<br/><br/>
                        Hoy 2,5 millones de chilenos sufren esta patología. Estudios nacionales han reportado que más de la mitad de los pacientes diabéticos son muchas veces diagnosticados tarde, es decir, cuando ya presentaban síntomas de que la enfermedad se había desarrollado.  Asegúrate de realizar tus exámenes preventivos regularmente y mantener hábitos de vida saludables para prevenir o retrasar el desarrollo de enfermedades como la diabetes y la enfermedad renal crónica.
                        <br/> 
                        <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("More",1)}>Leer menos...</span>
                    </div>
                    <div className="DiabetesResultSubtext" id="More1">
                        En este momento su riesgo de tener diabetes tipo 2 en un plazo de 10 años es {RiskCategory}. Esto significa que de cada 100 personas con el mismo puntaje, 17 desarrollarán diabetes en un plazo de 10 años, tú podrías ser una de ellas.<br/><br/>
                        Las intervenciones para prevenir o retrasar la diabetes tipo 2 en personas con prediabetes son factibles y económicas. En diversos estudios se ha determinado que las intervenciones de estilo de vida son más económicas que los medicamentos.
                        <br/><br/>
                        <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("Less",1)}>Leer más...</span>
                    </div>
                    <div className='DiabetesResultMedicalOrderContainer' style={{paddingInline:"2%"}}>
                        <div className='GeneralPurpleButtonFlex DiabetesResultMedicalOrder' onClick={() => getRecommendedExams()}>
                            Generar Órden Médica Personalizada
                        </div>
                    </div>
                    </>
                : RiskPoints > 14 ?
                <>
                <h2 className='DiabetesRiskCalculatorResultsTitlePart2 DiabetesRiskCalculatorResultsTextPadding'>Existe un alto riesgo de diabetes en tu vida</h2>
                <div className='DiabetesRiskCalculatorResultsTextSmallScreen HighRisk'> 
                    <div className='DiabetesRiskCalculatorResultsTextPart1B'>Riesgo {RiskCategory}</div> 
                    <div className='DiabetesRiskCalculatorResultsTextPart3B'>{RiskPoints} pts</div>
                </div>
                <div className='DiabetesRiskCalculatorResultsContainer2' style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/packs/DM00YMC020.png')",backgroundPosition: 'left',backgroundRepeat: 'no-repeat'}}>
                    <div className='DiabetesRiskCalculatorResultsText HighRisk'>
                        <div className='DiabetesRiskCalculatorResultsTextPart1B'>Riesgo {RiskCategory}</div> 
                        <div className='DiabetesRiskCalculatorResultsTextPart3B'>{RiskPoints} pts</div> 
                    </div>
                    <div className="HeartAgeCalculatorLeftSideResultHeartBlockMeterBlock">
                        <div clasName="DiabetesRiskCalculatorResultsRiskBar" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/RiskMeterHorizontalSimplified.png')",backgroundPosition: 'left',backgroundSize: 'contain',backgroundRepeat: 'no-repeat', width: "100%"}}>
                            <div className='DiabetesRiskCalculatorResultsCircleContain' style={{justifyContent: "right"}}>
                                <img alt="" className="DiabetesMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_HighRiskFace.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="DiabetesResultSubtext HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden" id="Less1">
                    En este momento su riesgo de tener diabetes tipo 2 en un plazo de 10 años es {RiskCategory}. Esto significa que de cada 100 personas con el mismo puntaje, {RiskPoints < 20 ? 33 : 50} desarrollarán diabetes en un plazo de 10 años, tú podrías ser una de ellas.<br/><br/>
                    Te recomendamos realizar realizar tu chequeo preventivo anual y acudir a tu médico de cabecera para comenzar un plan de mitigación de factores de riesgos cardiometabólicos intensivo.<br/><br/>
                    El Programa de Prevención de la Diabetes (DPP), un estudio amplio de prevención en personas con un riesgo alto de diabetes demostró que las intervenciones en el estilo de vida para perder peso y aumentar la actividad física redujeron la aparición de la diabetes tipo 2 en un 58% durante un periodo de 3 años. La reducción fue aún mayor (71%) en los adultos de 60 años o más.<br/><br/>
                    La diabetes puede provocar graves complicaciones y muerte prematura, pero las personas con esta enfermedad, en conjunto con su red de apoyo y proveedores de atención médica, pueden tomar algunas medidas para controlar la enfermedad y disminuir el riesgo de complicaciones.<br/><br/>
                    Pero, cuando es muy elevada la glicemia la persona puede padecer de:<br/>
                    Mucha sed.<br/>
                    Orinar muy seguido incluso en la noche.<br/>
                    Bajar de peso.<br/>
                    Tener mucha hambre, "ya en esa etapa tardía la diabetes está descompensada”.
                    <br/> 
                    <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("More",1)}>Leer menos...</span>
                </div>
                <div className="DiabetesResultSubtext" id="More1">
                    En este momento su riesgo de tener diabetes tipo 2 en un plazo de 10 años es {RiskCategory}. Esto significa que de cada 100 personas con el mismo puntaje, {RiskPoints < 20 ? 33 : 50} desarrollarán diabetes en un plazo de 10 años, tú podrías ser una de ellas.<br/><br/>
                    Te recomendamos realizar realizar tu chequeo preventivo anual y acudir a tu médico de cabecera para comenzar un plan de mitigación de factores de riesgos cardiometabólicos intensivo.
                    <br/><br/>
                    <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("Less",1)}>Leer más...</span>
                </div>
                <div className='DiabetesResultMedicalOrderContainer' style={{paddingInline:"2%"}}>
                    <div className='GeneralPurpleButtonFlex DiabetesResultMedicalOrder' onClick={() => getRecommendedExams()}>
                        Generar Órden Médica Personalizada
                    </div>
                </div>
                </>
                : null
                }
            </div>
            <div className='DiabetesRiskCalculatorResultsPart2'>
                <div className='DiabetesRiskCalculatorResultsTextPadding'>
                    <h5 className='DiabetesRiskCalculatorResults2Title'>Aprende a mejorar tus resultados</h5>
                    <h2 className='DiabetesRiskCalculatorResultsTitlePart2'>Cambia tus hábitos de vida y alcanza una vida saludable que te permita ganar años de buen vivir.</h2>
                </div>
                <img alt="" className='DiabetesRiskCalculatorResultsPart2HealthFactorsImage' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskResultHealthFactors.png'/>
                <div className='DiabetesRiskCalculatorResultsPart2HealthFactorsText'>La combinación de factores de cuidado de la salud puede permitir tanto a hombres cómo mujeres ganar una cantidad significativa de años  de vida. Si quieres mejorar tu salud ahora puedes conocer nuestra ruta del bienestar & autocuidado</div>
                {/*<div className='DiabetesRiskCalculatorResultsPart2Button1'>¿Cuántos años puedo ganar?</div>
                <div className='DiabetesRiskCalculatorResultsPart2Button2'>Conocer Ruta del Bienestar</div>*/}
            </div>
            <div className='DiabetesRiskCalculatorResultsPart3'>
                <div className='DiabetesRiskCalculatorResultsTextPadding'>
                    <h5 className='DiabetesRiskCalculatorResults2Title'>¿Cómo ganar años de vida?</h5>
                    <h2 className='DiabetesRiskCalculatorResultsTitlePart2'>La suma de estos factores te permitirá alcanzar entre 12 y 14 años para hombre y mujeres.</h2>
                </div>
                <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlocks'>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex'>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer'>
                            <img alt="" className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage1' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskAlcohol.png'/>
                        </div>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText'>
                            <h3 className='DiabetesRiskCalculatorResultsPart3HealthFactorsYears'>+5</h3>
                            <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsTitle'>Menos alcohol por más años de vida.</div>
                            <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle'>Si disminuyes el consumo de bebidas alcohólicas puedes alcanzar hasta 5 años más de vida</div>
                        </div>
                    </div>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex'>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer'>
                            <img alt="" className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage2' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskSmoking.png'/>
                        </div>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText'>
                            <h3 className='DiabetesRiskCalculatorResultsPart3HealthFactorsYears'>+4</h3>
                            <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsTitle'>Fumar nunca sumará a tu salud</div>
                            <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle'>Si dejas de fumar no solo mejoras tu salud diaria, también puedes ganar hasta 10 años de vida.</div>
                        </div>
                    </div>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex'>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer'>
                            <img alt="" className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage3' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskIMC.png'/>
                        </div>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText'>
                            <h3 className='DiabetesRiskCalculatorResultsPart3HealthFactorsYears'>+4</h3>
                            <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsTitle'>Cuida tu Índice de Masa Corporal (IMC)</div>
                            <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle'>Si cuidas tu índice de masa corporal puedes ganar 4 años aproximados de vida</div>
                        </div>
                    </div>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex'>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer'>
                            <img alt="" className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage4' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskCholesterol.png'/>
                        </div>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText'>
                            <h3 className='DiabetesRiskCalculatorResultsPart3HealthFactorsYears'>+7.2</h3>
                            <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsTitle'>Para cuidar el colesterol</div>
                            <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle Italic'>Limita los alimentos grasos y salados, come sin culpa de frutas de verduras y legumbres. Evita dulces, precocinados y alimentos ultra procesados.
                            Mantenerte en un peso saludable te puede sumar hasta 7.2 años de vida.</div>
                        </div>
                    </div>
                    <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex'>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer'>
                            <img alt="" className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage5' src='https://static.yomecontrolo.cl/public/img/calculator/DiabetesRiskMovement.png'/>
                        </div>
                        <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText'>
                            <h3 className='DiabetesRiskCalculatorResultsPart3HealthFactorsYears'>+3.4</h3>
                            <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsTitle'>El movimiento es vida</div>
                            <div className='DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle Italic'>Te recomendamos agregar dentro de tu semana 150-300 min de actividades de intensidad moderada ó 75 a 150 min de ejercicios de intensidad alta. Lo importantes es que mantengas tu cuerpo en movimiento adaptado a tu condición física.
                            Puedes ganar hasta 3.4 años de vida</div>
                        </div>
                    </div>
                </div>
                <div className='DiabetesRiskCalculatorResultsPart3EndText'>La importancia de un estilo de vida saludable</div>
            </div>
            <div className="DiabetesRiskCalculatorInnerDiv2">
                <div className="DiabetesRiskCalculatorResultsPart3">
                    <div className="DiabetesRiskCalculatorResultsTextPadding">
                        <h5 className="HeartAgeCalculatorLeftSideResultPartName">Antecedentes del cálculo</h5>
                        <h2 className="HeartAgeCalculatorLeftSideResultTitle">Condiciones de Uso de la Calculadora de Salud</h2>
                    </div>
                    <div className="DiabetesRiskCalculatorLeftSideSubtitleEnd" id="More">¿A quienes está dirigida esta herramienta? <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("Less")}>Leer más...</span></div>
                    <div className="DiabetesRiskCalculatorLeftSideSubtitleEnd HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden" id="Less">
                        <b>¿A quienes está dirigida esta herramienta?</b>
                        <br/>
                        El test de FINDRISC está dirigido especialmente a las personas que presentan 2 o más factores de riesgo de diabetes mellitus, Tales como:
                            <ul>
                                <li>Personas que presenten sobrepeso u obesidad.</li>
                                <li>Mayores de 45 años de edad.</li>
                                <li>Antecedentes personales de niveles elevados de glucosa en sangre.</li>
                                <li>Antecedentes familiares de diabetes.</li>
                                <li>Personas sedentarias.</li>
                                <li>Mujeres con antecedente de diabetes gestacional o síndrome de ovario poliquístico.</li>
                                
                            </ul> 
                        <br/>
                        La escala FINDRISC es un instrumento de cribaje inicialmente diseñado para valorar el riesgo individual de desarrollar DM2 en el plazo de 10 años. Las principales variables que se relacionan con el riesgo de desarrollar DM en esta escala son: edad, IMC, el perímetro de la cintura, hipertensión arterial con tratamiento farmacológico y los antecedentes personales de glucemia elevada.
                        <br/><br/>
                        Pese a que en esta herramienta no ha sido validad en población chilena, su uso se encuentra  aprobada por la Federación Internacional de Diabetes (FID) y es utilizado en países como EE.UU., España, Grecia, Italia y Colombia  entre otros, para la identificación de personas con mayor riesgo de desarrollar diabetes tipo 2.
                        <br/>
                        <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("More")}>Leer menos...</span>
                    </div>
                </div>
            </div>
        </div>
    : null}
        <div className={Calculated ? "DiabetesRiskCalculatorInnerDiv2" : "DiabetesRiskCalculatorInnerDiv"} style={{display: 'block'}}>
            <div className="HeartAgeCalculatorBibliographyFlex" onClick={() => displayBiblio()}>
                <div className="HeartAgeCalculatorBibliography">Referencias Bibliográficas&ensp;</div>
                {ArrowUp ?
                <FontAwesomeIcon 
                    icon={faAngleDown} 
                    className='HeartAgeCalculatorBibliographyArrow fa-rotate-180'
                    />
                :
                <FontAwesomeIcon 
                    icon={faAngleDown} 
                    className='HeartAgeCalculatorBibliographyArrow'
                    />
                }
            </div>
            <div id='BiblioContent' className="HeartAgeCalculatorBibliographyContent HeartAgeCalculatorBibliographyContentHidden">
                1.- Lindström J, Tuomilehto J. The diabetes risk score: a practical tool to predict type 2 diabetes risk. Diabetes Care 2003;26(3):725-31. <a href="https://diabetesjournals.org/care/article/26/3/725/29197/The-Diabetes-Risk-ScoreA-practical-tool-to-predict" target="_blank" rel="noreferrer">Link</a>
            </div>
        </div>
    </div>
    <Newsletter/>
    </>
  );
}

export default DiabetesRiskCalculator;