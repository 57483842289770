function DiabetesRiskCalc(gender, age, imc, abdominal_perimeter, exercise, fruits_or_vegetables, hipertension_meds, hyperglycemia, close_family_diabetes, distant_family_diabetes){
  let points = 0;
  
  age = parseInt(age);
  imc = parseInt(imc);
  abdominal_perimeter = parseInt(abdominal_perimeter);
  exercise = parseInt(exercise);
  fruits_or_vegetables = parseInt(fruits_or_vegetables);
  hipertension_meds = parseInt(hipertension_meds);
  hyperglycemia = parseInt(hyperglycemia);
  close_family_diabetes = parseInt(close_family_diabetes);
  distant_family_diabetes = parseInt(distant_family_diabetes);

  if (age > 45 && age <= 54) {
    points += 2;
  }
  else if (age >= 55 && age <= 64) {
    points += 3;
  }
  else if (age > 64) {
    points += 4;
  }
  
  if (imc >= 25 && imc <= 30){
    points += 1;
  }
  else if (imc > 30){
    points += 3;
  }

  if (gender === "M"){
    if (abdominal_perimeter >= 94 && abdominal_perimeter <= 102){
      points += 3;
    }
    else if (abdominal_perimeter > 102){
      points += 4;
    }
  } else {
    if (abdominal_perimeter >= 80 && abdominal_perimeter <= 88){
      points += 3;
    }
    else if (abdominal_perimeter > 88){
      points += 4;
    }
  }
  
  if (!exercise) {
    points += 2;
  }

  if (!fruits_or_vegetables) {
    points += 1;
  }

  if (hipertension_meds) {
    points += 2;
  }

  if (hyperglycemia) {
    points += 5;
  }

  if (distant_family_diabetes && !close_family_diabetes) {
    points += 3;
  }
  else if (close_family_diabetes) {
    points += 5;
  }

  return points;
}

export default DiabetesRiskCalc;