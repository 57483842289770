import React, { useContext, useState, useEffect } from 'react';
import "../../Styles/FamilyForm.css";
import { PrimaryButton, SecondaryButton } from '../ThemeComponents/Buttons';
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../Context/UserInfoContext';
import { MedicalContext } from '../Context/MedicalContext';
import medhistory from "../../../JSONS/medhistory.json"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function FamilyForm() {
  const Navigate = useNavigate();
  const MySwal = withReactContent(Swal)

  const { FamilyMedHis, setFamilyMedHis,MedHis,DoB,Gender
  } = useContext(UserInfo);

  const {
    PerRecordsCodes, FamRecordsCodes
  } = useContext(MedicalContext);

  const age = new Date().getFullYear() - new Date(DoB).getFullYear();

  const [TaskStart, setTaskStart] = useState(false);
  const [Taskdone1, setTaskdone1] = useState(false);
  const [Taskdone2, setTaskdone2] = useState(false);
  const [ChosenMedhis, setChosenMedhis] = useState([]);

  const handleGender = (ActiveKey) => {
    let tmp = document.getElementById(ActiveKey);
    let tmpList = ChosenMedhis

    if(tmp.className.includes("GenderTileActive")) {
      tmp.className = tmp.className.replace("GenderTileActive", "GenderTile");
      let index = tmpList.indexOf(ActiveKey);
      if (index > -1) { 
        tmpList.splice(index, 1); 
      }
    } else {
      tmp.className = tmp.className.replace("GenderTile", "GenderTileActive");
      tmpList.push(ActiveKey)
    }
    setChosenMedhis([...tmpList])
  }

  function showCancer() {
    let tmp = document.getElementById("CancerButton");
    let tmp2 = document.getElementById("CancerDiv");
    let tmpList = ChosenMedhis

    if(tmp.className.includes("GenderTileActive")) {
      tmp.className = tmp.className.replace("GenderTileActive", "GenderTile");
      tmp2.className += " FamilyFormBlockHidden"
      for(let i=0;i<medhistory.Family_Cancer.length;i++) {
        if(tmpList.includes(medhistory.Family_Cancer[i])) {
          let index = tmpList.indexOf(medhistory.Family_Cancer[i]);
          tmpList.splice(index, 1);
        }
      }
    } else {
      tmp.className = tmp.className.replace("GenderTile", "GenderTileActive");
      tmp2.className = tmp2.className.replace(" FamilyFormBlockHidden", "");
    }
    setChosenMedhis([...tmpList])
  }

  useEffect(() => {
    if(age < 15) {
      Navigate("/recomendador/paso-1")
    }
    if(Taskdone1) {
      setTaskdone1(false)
      getRecommendedExams()
    }
    if(Taskdone2) {
      MySwal.close()
      document.body.scrollTop = 0; 
      document.documentElement.scrollTop = 0; 
      Navigate("/recomendador/paso-4")
    }
  })

  const getRecommendedPacks = async() => {
    //setTaskStart(true)
    MySwal.fire({
      position: "center",
      iconColor: "#6633FF",
      imageUrl: "https://static.yomecontrolo.cl/public/icons/ymc-loading.gif",
      title: "Cargando Orden Personalizada...",
      color: "#340066",
      imageWidth: 400,
      imageHeight: 400,
      showConfirmButton: false,
    })

    var params = {
      p_medhis: MedHis,
      f_medhis: ChosenMedhis
    };
  
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params)
    };

    try{
      
    const resp = await fetch("https://ymc-graph-api.azurewebsites.net/getrecommendedpacks", requestOptions)
     if (resp.status !== 200){
      MySwal.close()
      alert("there has been some error");
      return false;
     }
     const data = await resp.json();
     
     localStorage.setItem('AllPacks', JSON.stringify(data));

     setTaskdone1(true)
    }
    catch(error){
      MySwal.close()
      console.log("there has been an error login in")
      alert("Ocurrió un error procesando los resultados")
      return false
    }
  }

  const getRecommendedExams = async() => {

    let allMedHis = []
    allMedHis.push(...MedHis.map(key => PerRecordsCodes[key]))
    allMedHis.push(...ChosenMedhis.map(key => FamRecordsCodes[key]))

    var params = {
      meds: allMedHis,
      sex: Gender === 1 ? 'M' : 'F',
      age: age
    };
  
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params)
    };

    try{
      
    const resp = await fetch("https://ymc-graph-api.azurewebsites.net/getrecommendedexams", requestOptions)
      if (resp.status !== 200){
        MySwal.close()
        alert("there has been some error");
        return false;
      }
      const data = await resp.json();
      //console.log(data)
      
      localStorage.setItem('PreviewRelatedExams', JSON.stringify(data));

      setTaskdone2(true)
    }
    catch(error){
      MySwal.close()
      console.log("there has been an error login in")
      alert("Ocurrió un error procesando los resultados")
      return false
    }
  }

  return (
    <div className="FamilyFormDiv">
      <div className="FamilyFormBlock">
        <div className="FamilyFormTitle">
          ¿Sabes si alguno de ellos ha sido diagnosticado o está en tratamiento por alguna de estas patologías?
        </div>
        <div className='FamilyFormButtons'>
          {medhistory.Family_Patho.map((history) => (
            <div className={"FamilyFormIndividualButton  GenderTile rounded-2 "} key={history} id={history} onClick={() => handleGender(history)}>
              {history} </div>
          ))}
          <div className={"FamilyFormIndividualButton  GenderTile rounded-2 "} id="CancerButton" onClick={() => showCancer()}>
            Cáncer </div>
        </div>
      </div>

      <div className="FamilyFormBlock FamilyFormBlockHidden" id="CancerDiv">
        <div className="FamilyFormTitle">
          Seleccione el(los) tipo(s) de cáncer
        </div>
        <div className='FamilyFormButtons'>
          {medhistory.Family_Cancer.map((history) => (
            <div className={"FamilyFormIndividualButton  GenderTile rounded-2 "} key={history} id={history} onClick={() => handleGender(history)}>
              {history} </div>
          ))}
        </div>
      </div>
      <div className='ButtonsMedHis'>
        <SecondaryButton ClassName={"MedicalHistoryRouteBackButton rounded-2 text-center"} label={"Volver atrás"} onClick={() => Navigate(-1)} />
        {TaskStart ?
        <PrimaryButton ClassName={"MedicalHistoryRouteNextButton rounded-2 text-center"} label={"Cargando..."}/>
        : <PrimaryButton ClassName={"MedicalHistoryRouteNextButton rounded-2 text-center"} label={"Siguiente"} onClick={() => getRecommendedPacks()} />}
     </div>
    </div>
    
  );
}

export default FamilyForm;