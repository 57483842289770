import React from 'react';
import "./Styles/DiabetesRiskCalculator.css";
import NavBar from '../NavBar';
import Footer from '../Footer';
import DiabetesRiskCalculator from './Components/DiabetesRiskCalculatorRoute/DiabetesRiskCalculator';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import UserInfoProvider from './Components/Context/UserInfoContext';
import MedicalContextProvider from './Components/Context/MedicalContext';

function DiabetesRiskCalculatorRoute() {
    return (
        <UserInfoProvider>
            <MedicalContextProvider>
                <div className="DiabetesRiskCalculatorRoutePage">
                    <div className="DiabetesRiskCalculatorRouteNavBar">      
                        <NavBar/>
                    </div>
                    <div className="DiabetesRiskCalculatorRouteForm">
                        <DiabetesRiskCalculator/>
                    </div>
                    <div className="DiabetesRiskCalculatorRouteFooter">
                        <Footer/>
                    </div>
                </div>
            </MedicalContextProvider>
       </UserInfoProvider>
    );
}

export default DiabetesRiskCalculatorRoute;