import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HealthCalculator = () => {

    const Navigate = useNavigate();

    const [Question, setQuestion] = useState("Calc1");

    function SubmitDiscover() {
        if(Question === "Calc1") {
            Navigate("/edad-del-corazon")
        } else if(Question === "Calc2") {
            Navigate("/recomendador/paso-1")
        } else {
            Navigate("/riesgo-diabetes")
        }
    }

    return (
        <div className='HealthCalculatorDiv'>
            <div className='HealthCalculatorDivInner' style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/BackgroundOneCorner.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
                <div className='HealthCalculatorDivInnerFlex'>
                    <div className='HealthCalculatorDivLeftSide'>
                        <div className='HealthCalculatorDivUpTitle'>Calculadora de salud</div>
                        <h1 className='HealthCalculatorDivTitle'>¿Quieres saber sobre tu salud o si eres paciente de riesgo de forma online?</h1>
                        <div className='HealthCalculatorDivSubtitle'>Usa nuestra calculadora de salud para identificar indicadores de riesgo que puedan poner en riesgo tu salud, así saber el riesgo de portar enfermedades crónicas cómo la diabetes de manera prematura.</div>
                        <div className='HealthCalculatorDivDropdownBlock'>
                            <select defaultValue={"Calc1"} className='HealthCalculatorDivDropdown' id="QuestionSelect" 
                            onChange={(e) => setQuestion(e.target.value)}>
                                <option key="Calc1" value="Calc1">¿Estoy en riesgo cardiovascular?</option>
                                <option key="Calc2" value="Calc2">¿Qué exámenes preventivos debiese tomar?</option>
                                <option key="Calc3" value="Calc3">¿Tengo riesgo de contraer diabetes?</option>
                            </select>
                            <div className="GeneralPurpleButtonFlex HealthCalculatorDivDropdownButtonFlex" onClick={() =>{ SubmitDiscover(); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
                                <div className="GeneralPurpleButtonText">Descúbrelo</div>
                                <img className="GeneralPurpleButtonIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                            </div>
                        </div>
                    </div>
                    <div className='HealthCalculatorDivRightSide'>
                        <img className="HealthCalculatorDivRightSideImage" src="https://static.yomecontrolo.cl/public/img/calculator/HealthCalculatorsPage.png"/>
                    </div>
                </div>
            </div>
            <div className='HealthCalculatorDivLowerFlex'>
                <div className='HealthCalculatorDivLowerBlock' style={{ backgroundColor: "#10DFFF", color: "#6633FF" }}>
                    <div className='HealthCalculatorDivLowerBlockInner'>
                        <div className="HealthCalculatorDivRightLowerBlockImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/HealthCalculatorCardioImage.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
                        <h3 className="HealthCalculatorDivRightLowerBlockTitle">Edad del corazón y riesgo CV</h3>
                        <h5 className="HealthCalculatorDivRightLowerBlockSubtitle">Conoce la edad de tu corazón y posibles riesgos Cardiovasculares</h5>
                        <div className="HealthCalculatorDivRightLowerBlockSubtitle2">¿Estas en posible riesgo cardiovascular?</div>
                        <div className="GeneralPurpleButtonFlex HealthCalculatorDivDropdownButtonFlex" onClick={() =>{Navigate("/edad-del-corazon"); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
                            <div className="GeneralPurpleButtonText">Descúbrelo</div>
                            <img className="GeneralPurpleButtonIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                        </div>
                    </div>
                </div>
                <div className='HealthCalculatorDivLowerBlock' style={{ backgroundColor: "#6633FF", color: "white" }}>
                    <div className='HealthCalculatorDivLowerBlockInner'>
                        <div className="HealthCalculatorDivRightLowerBlockImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/HealthCalculatorOrderImage.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
                        <h3 className="HealthCalculatorDivRightLowerBlockTitle">Orden Médica personalizada</h3>
                        <h5 className="HealthCalculatorDivRightLowerBlockSubtitle">Crea una orden médica de exámenes a la medida para cuidar tu salud</h5>
                        <div className="HealthCalculatorDivRightLowerBlockSubtitle2">¿No posees exámenes o no sabes donde comenzar?</div>
                        <div className="GeneralPurpleButtonFlex HealthCalculatorDivDropdownButtonFlex" onClick={() =>{Navigate("/recomendador/paso-1"); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
                            <div className="GeneralPurpleButtonText">Descúbrelo</div>
                            <img className="GeneralPurpleButtonIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                        </div>
                    </div>
                </div>
                <div className='HealthCalculatorDivLowerBlock' style={{ backgroundColor: "#CCCCFF", color: "#6633FF" }}>
                    <div className='HealthCalculatorDivLowerBlockInner'>
                        <div className="HealthCalculatorDivRightLowerBlockImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/HealthCalculatorDiabetesImage.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
                        <h3 className="HealthCalculatorDivRightLowerBlockTitle">Diabetes - Test Findrisk</h3>
                        <h5 className="HealthCalculatorDivRightLowerBlockSubtitle">Descubre los factores que te hacen paciente de riesgo</h5>
                        <div className="HealthCalculatorDivRightLowerBlockSubtitle2">¿Tienes riesgo de desarrollar diabetes?</div>
                        <div className="GeneralPurpleButtonFlex HealthCalculatorDivDropdownButtonFlex" onClick={() =>{Navigate("/riesgo-diabetes"); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
                            <div className="GeneralPurpleButtonText">Descúbrelo</div>
                            <img className="GeneralPurpleButtonIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HealthCalculator;
