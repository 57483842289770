import React, { useState, useEffect } from 'react';
import "./Styles/Contact.css";
import NavBar from '../NavBar';
import FAQ from './Components/ContactRoute/FAQ';
import Contact from './Components/ContactRoute/Contact';
import Newsletter from './Components/ContactRoute/Newsletter';
import { useLocation } from 'react-router-dom';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Footer from '../Footer';
import MakeItEasy from "./Components/Landing/MakeItEasy"

function ContactRoute() {

    const [OneTime,setOneTime] = useState(true)
    const [OneTime2,setOneTime2] = useState(true)
    const location = useLocation();

    const executeScroll = () => document.getElementById('anchor-name').scrollIntoView();

    useEffect(() => {
        if(OneTime & location.search === "?scroll=FAQ" && document.getElementById('Fullpage')) {
            executeScroll()
            setOneTime(false)
            setOneTime2(true)
            //console.log(location)
        }

        if(OneTime2 && location.search === "" && document.getElementById('Fullpage')) {
            setOneTime(true)
            setOneTime2(false)
            //console.log(location)
        }
    })
    
    return (
        <>
            <div className="ContactRoutePage" id='Fullpage'>
                <div className="ContactRouteNavBar">      
                    <NavBar/>
                </div>
                <div className="ContactRoute">
                    <Contact/>
                    <div id="title-element" style={{position: "relative"}}>
                        <div id="anchor-name" style={{position: "absolute", top: "-100px", left: 0}}></div>
                    </div>
                    <div>
                    <FAQ/>
                    </div>
                    <MakeItEasy/>
                    <Newsletter/>
                </div>
                <div className="ContactContact">
                    <Footer/>
                </div>
            </div>
        </>
    );
}

export default ContactRoute;