import React from 'react';
import "./Styles/HealthCalculator.css";
import NavBar from '../NavBar';
import Footer from '../Footer';
import HealthCalculator from './Components/HealthCalculatorRoute/HealthCalculator';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import UserInfoProvider from './Components/Context/UserInfoContext';
import MedicalContextProvider from './Components/Context/MedicalContext';

function HealthCalculatorRoute() {
    return (
        <UserInfoProvider>
            <MedicalContextProvider>
                <div className="HealthCalculatorRoutePage">
                    <div className="HealthCalculatorRouteNavBar">      
                        <NavBar/>
                    </div>
                    <div className="HealthCalculatorRouteForm">
                        <HealthCalculator/>
                    </div>
                    <div className="HealthCalculatorRouteFooter">
                        <Footer/>
                    </div>
                </div>
            </MedicalContextProvider>
       </UserInfoProvider>
    );
}

export default HealthCalculatorRoute;